import { useState } from "react";
import { Card, Flex, Typography } from "../../../components/UI";
import "./Tutorial.css";
import tutorial1 from "../../../assets/images/tutorial/desktopScreen-hp.png";
import AnimatedComponent from "../../../components/AnimatedComponent/AnimatedComponent";

const Tutorial = () => {
  const [isEnlargedImg, setIsEnlargedImg] = useState(false);

  const toggleEnlarge = () => {
    setIsEnlargedImg(!isEnlargedImg);
  };

  const drawCards = (startIndex: number, endIndex: number, cssClass: string) => {
    return TutorialCards.slice(startIndex, endIndex).map((c, i) => {
      return (
        <Card
          key={i}
          cssClass={`tutorial-card staggered-animation-3 staggered-animation animation-fade-in-${cssClass} animation-fade-in`}
        >
          <Typography.Title color="currentColor" level={2}>
            {c.title}
          </Typography.Title>
          <Typography.Paragraph>{c.text}</Typography.Paragraph>
        </Card>
      );
    });
  };

  return (
    <AnimatedComponent
      animationClass="staggered-animation-3 animation-fade-in-translateY animation-fade-in"
      asContainer
    >
      <Card cssClass="hp-card" title={" מה אנחנו מציעים?"}>
        <Flex justify="space-between">
          <Flex style={{ width: "30%" }} flexDirection="column">
            {drawCards(0, 3, "translateX")}
          </Flex>
          <div
            className={`flex-center tutorial-img-wrapper ${
              isEnlargedImg ? "enlarged" : ""
            }  animation-fade-in-opacity animation-fade-in`}
          >
            <img
              onClick={toggleEnlarge}
              className={`tutorial-img ${isEnlargedImg ? "enlarged" : ""}`}
              src={tutorial1}
              alt="tutorial"
            />
          </div>
          <Flex style={{ width: "30%" }} flexDirection="column">
            {drawCards(3, 6, "translateY")}
          </Flex>
        </Flex>
      </Card>
    </AnimatedComponent>
  );
};

export default Tutorial;

const TutorialCards = [
  {
    title: "גרסת אתר",
    text: "ניתן לבחור בין גרסאות תצוגה שונות , לשיפור חווית השימוש בהתאמה אישית.",
    img: tutorial1,
  },
  {
    title: "בחירת חנויות",
    text: "אתם בוחרים את החנויות עבורם תרצו לבצע השוואה , ההמלצה שלנו היא עד 6 חנויות.",
    img: tutorial1,
  },
  { title: "מיון עגלה", text: "מיינו את העגלה שלכם לצורך שיפור חווית הקנייה.", img: tutorial1 },
  {
    title: "קנייה מהירה",
    text: "ניתן ליצור רשימת קניות בטקסט חופשי , אנו נביא לכם את המוצרים מרוכזים במקום אחד.",
    img: tutorial1,
  },
  {
    title: "השוואת מחירים",
    text: "מיד בסיום מילוי העגלה , נעבור לאיזור השוואת המחירים, פה נוכל להשוות בין מה שבחרנו לחנויות האחרות בצורה נוחה ומתומצתת.",
    img: tutorial1,
  },
  {
    title: "החלפת פריטים",
    text: "לא תמיד נוכל למצוא את הפריט המבוקש , ייתכן וחסר במלאי , או תיאור המוצר שונה בין החנויות , לכן אנו מאפשרים לכם בזמן ההשוואה , לבצע חיפוש חופשי לפריטים חסרים.",
    img: tutorial1,
  },
];
