import { useCallback, useEffect, useMemo, useState } from "react";
import { ButtonGroup, ButtonInGroup, Card, Flex, Modal, Typography } from "../../../../components/UI";
import useCustomContext from "../../../../hooks/useCustomContext/useCustomContext";
import { CartIcon, DragAndDropIcon, SearchIcon, SettingsIcon } from "../../../../components/UI/Icons";

import { useTour } from "../../../../components/Tour/TourContext";
import { screenSizes } from "../../../../components/UI/index.hooks";
import StoresToCompareOption from "./options/storesToCompare";
import SearchInCartOption from "./options/searchInCart";
import comparisonPageTour from "./options/cartComparisonTour/cartComparisonTour";
import ChangeViewOption from "./options/changeView";
import { usePageComparisonSettings } from "../../../../Zustand";
import DragAndDropStoresToCompareOption from "./options/DragAndDropStore";
import useLocalStorage, { localStorageKeys } from "../../../../hooks/useLocalStorage";

type PageOption = "select_stores" | "dd_carts_stores" | "search" | "change_view";

const TOUR_CURRENT_VERSION = 1;

const PageOptions = () => {
  const {
    user: { language },
  } = useCustomContext();
  const { startTour, currentStep } = useTour();
  const [tourStorageData, setTourStorageData] = useLocalStorage(localStorageKeys.TourComparisonPage, {
    seen: false,
    version: 1,
  });

  const [isOpenModal, setIsOpenModal] = useState(false);
  const { selectedView } = usePageComparisonSettings();

  const [selectedOption, setSelectedOption] = useState<PageOption>("select_stores");

  const drawSelectedOption = useCallback(() => {
    switch (selectedOption) {
      case "select_stores":
        return <StoresToCompareOption />;
      case "search":
        return <SearchInCartOption />;
      case "change_view":
        return <ChangeViewOption language={language} />;
      case "dd_carts_stores":
        return <DragAndDropStoresToCompareOption />;

      default:
        return <StoresToCompareOption />;
    }
  }, [selectedOption, language]);

  const label = {
    select_stores: "בחירת חנויות",
    search: "חיפוש פריטים",
    change_view: "שינוי ערכת מראה",
    dd_carts_stores: "החלף מיקום עגלות",
  };

  const startComparisonPageTour = useCallback(() => {
    startTour(comparisonPageTour);
  }, [startTour]);

  const handleSelectedOption = (option: PageOption) => {
    setSelectedOption(option);
    if (window.innerWidth < screenSizes.sm) setIsOpenModal(true);
  };

  const buttons = useMemo(() => {
    const commonButtons: ButtonInGroup[] = [
      {
        onClick: () => handleSelectedOption("select_stores"),
        dataTour: `btn-select_stores`,
        icon: CartIcon,
        isDefaultActive: true,
      },

      {
        onClick: () => handleSelectedOption("dd_carts_stores"),
        dataTour: "btn-dd_carts_stores",
        icon: DragAndDropIcon,
      },
      {
        onClick: () => handleSelectedOption("change_view"),
        dataTour: "pc-option-btn_change_view",
        icon: SettingsIcon,
      },
    ];

    if (selectedView === "desktop") {
      // Tour should be last and hard to work on mobile
      commonButtons.push({
        label: "ℹ",
        onClick: () => startComparisonPageTour(),
        dataTour: "btn-tour",
        activeColor: "transparent",
      });

      commonButtons.splice(1, 0, {
        onClick: () => handleSelectedOption("search"),
        dataTour: "pc-option-btn_search",
        icon: SearchIcon,
      });
    }

    return commonButtons;
  }, [startComparisonPageTour, selectedView]);

  const DrawOptionsPerScreenSize = () => {
    if (window.innerWidth < screenSizes.sm) {
      return (
        <Modal
          style={{ backgroundColor: "var(--white)" }}
          language={language}
          withLogo={false}
          withFooter={false}
          maskClosable
          onCancel={() => setIsOpenModal(false)}
          visible={isOpenModal}
          title={label[selectedOption]}
        >
          <Flex align="center" gap={10} responsive={{ sm: { flexDirection: "column", gap: 15 } }}>
            {drawSelectedOption()}
          </Flex>
        </Modal>
      );
    }

    return (
      <>
        <Typography.Title level={5}> {label[selectedOption]}</Typography.Title>
        <Flex
          align="center"
          justify="space-between"
          gap={10}
          style={{ flex: selectedOption !== "dd_carts_stores" ? 1 : 0 }}
          responsive={{ md: { flexDirection: "column", gap: 15 } }}
        >
          {drawSelectedOption()}
        </Flex>
      </>
    );
  };

  useEffect(() => {
    // show tour only if user saw tour or storage tour is older version
    if (!tourStorageData.seen || tourStorageData.version < TOUR_CURRENT_VERSION) {
      startComparisonPageTour();
      setTourStorageData({ seen: true, version: 1 });
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!tourStorageData.seen && currentStep > comparisonPageTour.length / 2) {
      setTourStorageData({ ...tourStorageData, seen: true });
    }
    // eslint-disable-next-line
  }, [currentStep]);

  return (
    <Card dataTour="page-options">
      <Flex
        wrap="wrap"
        justify="space-between"
        gap={30}
        align="center"
        responsive={{ md: { flexDirection: "column-reverse" } }}
      >
        {DrawOptionsPerScreenSize()}

        {/* TODO : using store Vs store somehow */}
        <ButtonGroup
          gap={10}
          isLabelHidden
          height={30}
          buttons={buttons}
          buttonsColor="var(--black)"
          buttonsActiveColor="var(--primary-contrast-color)"
          buttonsHoverColor="var(--bg-secondary-color)"
        />
      </Flex>
    </Card>
  );
};

export default PageOptions;
