import { TourDict } from "../../../../../../../components/Tour/TourHelpers";
import { screenSizes } from "../../../../../../../components/UI/index.hooks";

const isDesktop = window.innerWidth > screenSizes.sm;

export const cartTourDict: TourDict = {
  allCarts: {
    target: isDesktop ? "[data-tour=all-carts]" : ".cart-compare-single-item-view_wrapper",
    placement: "center",
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew: "ברוכים הבאים לדף ההשוואה! כאן תוכלו לראות איך פריטים משתקפים בין העגלות. נלווה אתכם להכיר את הממשק.",
    },
  },
  favoriteHeader: {
    target: isDesktop ? ".big-cart-item" : ".cart-compare-single-item-view_top_carousel",
    placement: isDesktop ? "top" : "bottom",
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew: "זוהי העגלה הראשית שנבנתה לפי בחירותיכם. אותה אתם כבר מכירים , בואו נמשיך לעגלות האחרות.",
    },
  },
  allCartsPostFavoriteCart: {
    target: "[data-tour=all-carts]",
    placement: "center",
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew:
        "את העגלות האחרות הרכבנו עבורכם. מומלץ לבדוק אותנו , כדי להקל עליכם בבדיקה הצמדנו אייקון לכל פריט שמסביר את אופי ההתאמה",
    },
  },
  itemIconExample: {
    target: ".data-tour-icon-match",
    placement: isDesktop ? "top" : "left",
    skipOnNotFoundTarget: true,
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew: "לדוגמא אייקון זה מציין התאמה מלאה. אייקונים שונים עשויים לדרוש התייחסות שונה.",
    },
  },
  footerMissingItems: {
    target: "[data-tour=cart-missing-items] .data-tour-info",
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew: "חסרים פריטים? הקליקו כדי לראות את הפריטים שחסרים בעגלה זו",
    },
    skipOnNotFoundTarget: true,
  },
  footerTotalOther: {
    target: "[data-tour=cart-other-total]",
    skipOnNotFoundTarget: true, //skip for mobile
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew:
        "סכום העגלה מוצג כאן. אך שימו לב שטיפלתם בפריטים החסרים על מנת שההשוואה תהיה נכונה. יש פריטים חסרים? נסביר בהמשך.",
    },
  },
  footerLunchCartBtn: {
    target: "[data-tour=open-lunch-cart-modal]",
    waitForInteraction: isDesktop ? true : false,
    skipOnNotFoundTarget: isDesktop ? false : true,
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew: "ברגע שנבחר רשת רצויה נלחץ כאן כדי להעביר את הפריטים אליה",
    },
  },
  luncCartModal: {
    target: ".modal",
    awaiting: true,
    placement: "center",
    dotDisabled: true,
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew:
        "עוד לא סיימנו את המדריך אבל קפצנו לשלב האחרון בהשוואה. ברגע שבחרתם רשת אליה תרצו להעביר את העגלה שלכם , תגיעו לכאן!",
    },
  },
  lunchCartModalPriceComparison: {
    target: "[data-tour=store-vs-store]",
    placement: "top",
    awaiting: true,
    dotDisabled: true,
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew:
        "שנייה לפני שתמלאו את פרטי ההתקשרות לרשת אנחנו מוודאים איתכם שהרשת שבחרתם באמת יותר זולה מהרשת הראשית שדרכה מילאתם את העגלה שלכם. פה אנחנו עושים חישוב לסכום העגלה כאשר אנחנו מתייחסים אל ורק לפריטים שקיימים ב-2 העגלות.",
    },
  },
  luncCartModalForm: {
    target: "[data-tour=login-to-store-form]",
    dotDisabled: true,
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew:
        "אחרי שוידאתם שאכן זו העגלה הרצויה עבורכם , אין טעם למלא הכל מחדש ברשת הנבחרת , מלאו את פרטי החיבור שלכם לרשת , אשרו , המתינה מספר רגעים והפריטים שבחרתם כאן , יחכו לכם באתר הרשת.",
    },
  },
  lunchCartModalClose: {
    target: "[data-tour=modal-close-btn]",
    placement: "bottom",
    dotDisabled: true,
    waitForInteraction: true,
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew: "בואו נמשיך , אך קודם סגרו חלון זה.",
    },
  },
};
export const notFoundItemDict: TourDict = {
  allCarts2: {
    target: "[data-tour=all-carts]",
    placement: "center",
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew: "טוב אחרי שהבנו את מבנה העגלות הגיע הזמן להבין איך לטפל בפריטים שלא נמצאה להם התאמה",
    },
  },
  legend: {
    target: "[data-tour=legend-icons]",
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew:
        " מקרא זה מסביר את ההתאמות לכל פריט. המשך המדריך בחלק זה תלוי אם יש לכם פריטים שחסרים. המדריך ידלג לשלבים הבאים במידה ואין שום חוסרים, אך כדאי לדעת שגם במקרה של חוסרים וגם במקרה של התאמות לא לשביעות רצונכם תמיד תוכלו להחליף את הפריט המבוקש בהקלקה עליו.",
    },
  },
  notFounditemClick: {
    target: "[data-tour=not-found-item]",
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew: "מוצר חסר! לחצו כדי לראות אפשרויות.",
    },
    waitForInteraction: true,
    skipOnNotFoundTarget: true,
  },
  modalWrapper: {
    skipOnNotFoundTarget: true,
    dotDisabled: true,
    awaiting: true,
    target: ".modal",
    placement: "center",
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew:
        "הגעתם לכאן כי הפריט שעליו אתם מסתכלים לא קיים ברשת שעגלה זו מיוחסת אליה. תוכלו לבצע פעולות לבחירתם , אך דעו שאפשרויות אלו קיימות גם במקרים שהתאמנו לכם פריט אך הוא לא לשביעות רצונכם",
    },
  },
  modalSearch: {
    target: "[data-tour=not-found-item-search-modal]",
    skipOnNotFoundTarget: true,
    dotDisabled: true,
    placement: "bottom",
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew: "חפשו פריט חלופי מהרשת לעגלה זו ואשרו עדכון.",
    },
  },
  removeMissingItem: {
    skipOnNotFoundTarget: true,
    dotDisabled: true,
    target: ".modal [data-tour=modal-confirm]",
    placement: "top",
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew: "אפשרות נוספת היא פשוט לוותר על הפריט מהעגלה הראשית , כך שפריט זה לא יופיע בשום עגלה",
    },
  },
  closeModal: {
    target: "[data-tour=modal-close-btn]",
    waitForInteraction: true,
    dotDisabled: true,
    content: {
      english: "",
      arabic: "",
      russian: "",
      hebrew: "סיימתם להתאים פריטים? סגרו את החלון.",
    },
  },
};
