import React, { useState, useReducer, Dispatch, SetStateAction, FC } from "react";
import serverAPI from "../../../api/server";
import { Button, Flex, InputUI } from "../../UI";
import "../Authentication.css";
import { EmailIcon, PasswordHideIcon, PasswordShowIcon } from "../../UI/Icons";
import { AuthenticationFormReducer, AuthenticationInitialState } from "../AuthReducer/AuthReducer";
import { dictAuth } from "../dictionary";

import { updateUserContextFromSnakeToCamelCase } from "../../../utils/utils";
import { AuthType } from "../Authentication";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import InfoBox, { InfoBoxType } from "../../UI/InfoBox/InfoBox";
import csrfToken from "../../../models/Tokens/CsrfToken";
import { useNavigate } from "react-router-dom";

type Props = {
  setAuthType?: Dispatch<SetStateAction<AuthType>>;
  handleClose: () => void;
  isFullPage?: boolean;
};

const Login: FC<Props> = ({ setAuthType, handleClose, isFullPage }) => {
  const {
    setUser,
    user: { language },
  } = useCustomContext();
  const navigate = useNavigate();
  const [togglePasswordVisible, setTogglePasswordVisible] = useState(false);
  const [formState, dispatch] = useReducer(AuthenticationFormReducer, AuthenticationInitialState);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState<{ type: InfoBoxType; content: string }>({
    type: "gray",
    content: "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  const togglePassword = () => {
    setTogglePasswordVisible((p) => !p);
  };

  const handleLogin = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await serverAPI("users/login", { email: formState.email, password: formState.password });
      if (res.statusCode === 200) {
        csrfToken.setToken(res.data.csrfToken);
        setResponseMessage({ type: "success", content: dictAuth.loginSuccess[language] });
        const user = updateUserContextFromSnakeToCamelCase(res.data.user);
        user.isUser = true;
        setUser(user);

        isFullPage && navigate("/");
        handleClose();
      } else {
        setResponseMessage({ content: dictAuth.userNotFound[language], type: "warning" });
      }
    } catch (error) {
      setResponseMessage({ type: "error", content: dictAuth.loginFailed[language] });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleLogin} className="auth-card-wrapper">
      <p className="auth-card-subtitle">{dictAuth["loginMeetingMessage"].hebrew}</p>
      <Flex flexDirection="column" gap={30}>
        <InputUI
          id="email"
          required
          name="SET_EMAIL"
          autoComplete="current-email"
          type="text"
          label={dictAuth.email[language]}
          value={formState.email}
          onChange={handleInputChange}
          icon={<EmailIcon size={"1em"} color="currentColor" />}
        />

        <InputUI
          id="password"
          name="SET_PASSWORD"
          required
          autoComplete="current-password"
          type={togglePasswordVisible ? "text" : "password"}
          label={dictAuth.password[language]}
          value={formState.password}
          onChange={handleInputChange}
          icon={
            togglePasswordVisible ? (
              <PasswordHideIcon clickable onClick={togglePassword} />
            ) : (
              <PasswordShowIcon clickable onClick={togglePassword} />
            )
          }
        />
      </Flex>

      <div className="space-height-lg" />
      <Button htmlType="submit" loading={loading}>
        {dictAuth.login[language]}
      </Button>

      {setAuthType && (
        <p className="redirect-to">
          <span onClick={() => setAuthType("register")}>{dictAuth.notAMember[language]}</span>
          <span onClick={() => setAuthType("forgetPassword")}>{dictAuth.forgotPassword[language]}?</span>
        </p>
      )}
      {responseMessage.content && (
        <InfoBox
          language={language}
          content={responseMessage.content}
          type={responseMessage.type}
          cssClass="response-message"
        />
      )}
    </form>
  );
};

export default Login;
