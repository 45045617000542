import React, { FC, useRef, useState, useEffect, CSSProperties, useCallback, ReactNode } from "react";

export interface VirtualizedListProps {
  height?: number | string;
  itemCount: number;
  itemSize: number;
  visibleItems?: number;
  children: (index: number, style: CSSProperties) => React.ReactNode;
  onScroll?: (scrollTopSingle: number) => void;
  scrollTopProp?: number;
  width?: string | number;
  header?: ReactNode;
  footer?: ReactNode;
}

// #tag: performance (CHAT GPT - similar to react-window , but smaller in size, used for cart rendering)
const VirtualizedList: FC<VirtualizedListProps> = ({
  height = "100%",
  width,
  itemCount,
  itemSize,
  visibleItems,
  children,
  onScroll,
  scrollTopProp,
  header,
  footer,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);

  const totalHeight = itemCount * itemSize;
  // const visibleItemCount = visibleItems ?? typeof height === "number" ? Math.ceil(height as number / itemSize) : ;
  let visibleItemCount: number;
  if (typeof height === "number") {
    visibleItemCount = Math.ceil((height as number) / itemSize);
  } else if (visibleItems) {
    visibleItemCount = visibleItems;
  } else throw new Error("Can't assign string height without 'visibleItems' prop");

  const startIndex = Math.floor(scrollTop / itemSize);
  const endIndex = Math.min(itemCount - 1, startIndex + visibleItemCount);

  const onScrollInner = useCallback(() => {
    if (containerRef.current) {
      const newScrollTop = containerRef.current.scrollTop;
      setScrollTop(newScrollTop);

      // handle onScroll Prop
      onScroll && requestAnimationFrame(() => onScroll(newScrollTop));
      // onScroll && onScroll(newScrollTop);
    }
  }, [onScroll]);

  // useEffect with a small delay to simulate throttling
  useEffect(() => {
    const handleScroll = () => {
      requestAnimationFrame(onScrollInner);
    };

    const currentRef = containerRef.current;
    if (currentRef) {
      currentRef.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (currentRef) {
        currentRef.removeEventListener("scroll", handleScroll);
      }
    };
  }, [onScrollInner]);

  useEffect(() => {
    if (scrollTopProp && containerRef.current && containerRef.current.scrollTop !== scrollTopProp) {
      setScrollTop(scrollTopProp);
      containerRef.current.scrollTop = scrollTopProp;
    }
  }, [scrollTopProp]);

  return (
    <div ref={containerRef} style={{ overflowY: "auto", height, width }}>
      {header && <div style={{ position: "sticky", left: 0, top: 0, zIndex: 10 }}>{header}</div>}
      <div style={{ height: totalHeight, position: "relative" }}>
        {Array.from({ length: endIndex - startIndex + 1 }).map((_, index) => {
          const itemIndex = startIndex + index;
          const style: CSSProperties = {
            position: "absolute",
            top: itemIndex * itemSize,
            width: "100%",
            height: itemSize,
          };
          return children(itemIndex, style);
        })}
      </div>
      {footer && (
        <div
          style={{
            position: "sticky",
            bottom: 0,
            top:
              itemCount < visibleItemCount
                ? `calc(${typeof height === "string" ? height : `${height}px`} - ${itemSize}px)`
                : undefined,
            left: 0,
            zIndex: 100,
          }}
        >
          {footer}
        </div>
      )}
    </div>
  );
};

export default VirtualizedList;
