import { FC } from "react";
import { IconCustom } from "./types";

const CheckMark: FC<IconCustom> = ({ cssClass, color = "green", size = "1rem" }) => {
  return (
    <span className={cssClass} style={{ color: color, fontSize: size }}>
      &#10003;
    </span>
  );
};

export default CheckMark;
