import React, { Dispatch, FC, ReactNode, SetStateAction } from "react";
import { ProductDataType, StoreEn } from "../../../types/types";
import Promotion from "../Promotions/Promotion";
import AddToCartBtn from "../AddToCartBtn/AddToCartBtn";
import ChangeAmount from "../ChangeAmount/ChangeAmount";
import SelectedItem from "../../SelectedItem/SelectedItem";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import { useCartStore } from "../../../Zustand";

interface IProductCardGeneric {
  productData: ProductDataType;
  ProductCardBodyVersion: ReactNode;
  ProductCardFooterVersion: ReactNode;
  stores: StoreEn[];
  selectedStore: StoreEn;
  item_code: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  cssClass?: string;
  lastItemRef?: (node: Element | null) => void;
}
const ProductCardGeneric: FC<IProductCardGeneric> = ({
  ProductCardBodyVersion,
  ProductCardFooterVersion,
  stores,
  selectedStore,
  item_code,
  productData,
  isOpen,
  setIsOpen,
  cssClass,
  lastItemRef,
}) => {
  const {
    user: { language },
  } = useCustomContext();

  const { cart } = useCartStore();

  return (
    <>
      <div
        ref={lastItemRef}
        tabIndex={0}
        className={`product_card_wrapper_vvv ${cssClass ? cssClass : ""}`}
        aria-label={productData[stores[0]].item_name}
      >
        {/* Promotion version here is according version 1 due to we convert the entire data to fit it */}
        <Promotion allStores={stores} productData={productData} language={language} />
        {ProductCardBodyVersion}
        <div className="product_card_bottom">{ProductCardFooterVersion}</div>
        {!cart[item_code] ? (
          <AddToCartBtn language={language} item_code={item_code} productData={productData} />
        ) : (
          <ChangeAmount item_code={item_code} handleInfoClick={() => setIsOpen(true)} />
        )}
      </div>
      {isOpen && (
        <SelectedItem
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          selectedProductProp={productData}
          stores={stores}
          language={language}
          selectedStore={selectedStore}
        />
      )}
    </>
  );
};

export default ProductCardGeneric;
