import React from "react";
import { Segmented } from "../../../../../components/UI";
import { usePageComparisonSettings } from "../../../../../Zustand";
import { Language } from "../../../../../types/types";
// import { PageComparisonView } from "../../../../../types/types";

const POSSIBLE_VIEWS = {
  desktop: { hebrew: "דסקטופ", english: "Desktop", arabic: "", russian: "" },
  mobile: { hebrew: "מובייל", english: "Mobile", arabic: "", russian: "" },
} as const;

export type PageComparisonView = keyof typeof POSSIBLE_VIEWS; // "desktop" | "mobile"

type View = {
  key: PageComparisonView;
  label: string; // Actual label will depend on the selected language
};

const ChangeViewOption = ({ language }: { language: Language }) => {
  const { setSelectedView, selectedView } = usePageComparisonSettings();

  // Generate the options dynamically based on the selected language
  const options: View[] = Object.entries(POSSIBLE_VIEWS).map(([key, labels]) => ({
    key: key as PageComparisonView,
    label: labels[language], // Use the correct language for the label
  }));

  return (
    <Segmented
      defaultKey={selectedView}
      onChange={(v) => setSelectedView(v.key as PageComparisonView)}
      options={options}
    />
  );
};

export default ChangeViewOption;
