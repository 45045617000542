import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from "react";
import { TourStep } from "./TourHelpers";

interface TourContextType {
  steps: TourStep[];
  currentStep: number;
  isTourActive: boolean;
  startTour: (tourSteps: TourStep[]) => void;
  endTour: () => void;
  nextStep: () => void;
  prevStep: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
}

const TourContext = createContext<TourContextType | undefined>(undefined);

export const useTour = (): TourContextType => {
  const context = useContext(TourContext);
  if (!context) {
    throw new Error("useTour must be used within a TourProvider");
  }
  return context;
};

interface TourProviderProps {
  children: ReactNode;
}

export const TourProvider: React.FC<TourProviderProps> = ({ children }) => {
  const [steps, setSteps] = useState<TourStep[]>([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [isTourActive, setIsTourActive] = useState(false);

  const startTour = (tourSteps: TourStep[]) => {
    setSteps(tourSteps);
    setCurrentStep(0);
    setIsTourActive(true);
  };

  const endTour = () => {
    setIsTourActive(false);
    setSteps([]);
    setCurrentStep(0);
  };

  const nextStep = () => {
    const nextStep = currentStep + 1;
    if (nextStep >= steps.length) endTour();
    else setCurrentStep(nextStep);
  };
  const prevStep = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

  return (
    <TourContext.Provider
      value={{
        steps,
        currentStep,
        isTourActive,
        startTour,
        endTour,
        nextStep,
        prevStep,
        setCurrentStep,
      }}
    >
      {children}
    </TourContext.Provider>
  );
};
