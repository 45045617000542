import { create } from "zustand";
import { Cart, ProductDataType } from "../../types/types";

// Define the store type, including cart state and actions
type CartStore = {
  cart: Cart;
  isCartOpen: boolean;
  setIsCartOpen: (value: boolean | ((prev: boolean) => boolean)) => void;
  setCart: (cart: Cart | ((prevCart: Cart) => Cart)) => void;
  addToCart: (item_code: string, product: ProductDataType, amount: number) => void;
  updateCartItem: (item_code: string, amount: number) => void;
  removeFromCart: (item_code: string) => Cart;
  clearCart: () => void;
};

// Create Zustand store for the cart
const useCartStore = create<CartStore>((set, get) => ({
  cart: {},

  isCartOpen: false,

  setIsCartOpen: (value) => {
    set((state) => ({
      isCartOpen: typeof value === "function" ? value(state.isCartOpen) : value,
    }));
  },

  // ! special cases
  setCart: (newCart) =>
    set((state) => ({
      cart: typeof newCart === "function" ? newCart(state.cart) : newCart,
    })),

  // Add to cart or update existing item
  addToCart: (item_code, product, amount) =>
    set((state) => {
      // Check if the item already exists in the cart
      const existingItem = state.cart[item_code];

      return {
        cart: {
          ...state.cart,
          [item_code]: {
            amount: existingItem ? existingItem.amount + amount : amount,
            product,
          },
        },
      };
    }),

  // Update the quantity of a cart item
  updateCartItem: (item_code, amount) =>
    set((state) => {
      if (!state.cart[item_code]) return state; // Item not in cart

      const updatedAmount = state.cart[item_code].amount + amount;

      if (updatedAmount <= 0) {
        return get().removeFromCart(item_code);
      }

      return {
        cart: {
          ...state.cart,
          [item_code]: {
            ...state.cart[item_code],
            amount: updatedAmount,
          },
        },
      };
    }),

  // Remove an item from the cart
  removeFromCart: (item_code) => {
    let data: Cart = {};

    set((state) => {
      const newCart = { ...state.cart };
      delete newCart[item_code];
      data = newCart;
      return { cart: newCart };
    });
    return data;
  },

  // Clear all items in the cart
  clearCart: () => set({ cart: {} }),
}));

export default useCartStore;
