import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import ProfileSetting from "../components/ProfileSetting/ProfileSetting";
import ErrorPage from "../components/ErrorPage/ErrorPage";
import { autoLoginNewUserLoader } from "./loaders/user/autoLogin.loader";
import { ProtectedRoute } from "./loaders/ProtectedRoute/ProtectedRoute";
import { ProtectedResetPassword } from "./loaders/ProtectedResetPassword/ProtectedResetPassword";
import ResetPassword from "../components/Authentication/ResetPassword/ResetPassword";
import Homepage from "../pages/Homepage/Homepage";
import Contact from "../components/Contact/Contact";
import ProductShoppingList from "../components/ProductsShoppingList/ProductShoppingList";
import ProductsV1 from "../pages/Products/ProductsV1/ProductsV1";
import ProductsV2 from "../pages/Products/ProductsV2/ProductsV2";
import TestComponent from "../Tests/TestComponent";
import MobileDepartemntsNav from "../pages/MobileDepartmentsNav/MobileDepartmentsNav";
import { SiteContextType } from "../hooks/useCustomContext/useCustomContext";
import LegalPolicy from "../pages/Legal/LegalPolicy";
import AuthenticationPage from "../pages/AuthenticationPage/AuthenticationPage";
import CartComparisonPage from "../pages/CartComparisonPage/CartComparisonPage";

// const router = createBrowserRouter([
const router = (contextValues: SiteContextType) =>
  createBrowserRouter([
    {
      path: "/",
      element: <App />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: "/",
          element: <Homepage />,
        },
        {
          path: "/legal/termsOfUse",
          element: <LegalPolicy source="termsOfUse" />,
        },
        {
          path: "/legal/privacyPolicy",
          element: <LegalPolicy source="privacyPolicy" />,
        },
        {
          path: "/products/list",
          element: <ProductShoppingList />,
        },
        {
          path: "/products/:category",
          // element: contextValues.siteVersion === 1 ? <ProductsMain /> : <ProductsMainV2 />,
          element: contextValues.siteVersion === 1 ? <ProductsV1 /> : <ProductsV2 />,
        },
        {
          path: "/profile/:tab",
          element: <ProfileSetting />,
          loader: (req) => ProtectedRoute(req, contextValues),
        },
        {
          path: "/contact",
          element: <Contact />,
        },
        {
          path: "users/reset-password/:token",
          element: <ResetPassword />,
          loader: (req) => ProtectedResetPassword(req, contextValues),
        },
        {
          path: "cart/compare/",
          element: <CartComparisonPage />,
          loader: (req) => ProtectedRoute(req, contextValues),
        },
        {
          path: "categories/mobile",
          element: <MobileDepartemntsNav />,
        },

        {
          path: "app/test",
          element: process.env.NODE_ENV === "production" ? <ErrorPage /> : <TestComponent />,
        },
      ],
    },

    {
      path: "test",
      element: process.env.NODE_ENV === "production" ? <ErrorPage /> : <TestComponent />,
      errorElement: <ErrorPage />,
    },

    {
      path: "/login/:token",
      element: <App />,
      errorElement: <ErrorPage />,
      loader: (req) => autoLoginNewUserLoader(req, contextValues),
    },
    {
      path: "/login-page",
      element: <AuthenticationPage />,
      errorElement: <ErrorPage />,
    },
    {
      path: "error",
      element: <ErrorPage />,
    },
  ]);

export default router;
