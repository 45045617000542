import { Button, Card } from "../../UI";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import { useCartStore } from "../../../Zustand";

const SiteVersionSettings = () => {
  const { setSiteVersion, siteVersion } = useCustomContext();
  const { clearCart } = useCartStore();

  const handleChangeSiteVersion = () => {
    setSiteVersion((p) => {
      console.log("current version is ", p, "changed to other version now");
      if (p === 1) return 2;
      else return 1;
    });

    clearCart();
  };

  return (
    <div className="container flex-column flex-gap-20 flex-space-around">
      <Card subtitle="Explain how its works">
        Lorem, ipsum dolor sit amet consectetur adipisicing elit. Reiciendis doloremque quaerat, dicta iusto nam illo
        adipisci id ad veniam qui facilis necessitatibus autem quis voluptate totam impedit labore, quia at?
      </Card>

      <div className="responsive-flex-column flex-center flex-gap-20">
        <Card style={{ width: "50%" }}>v1</Card>
        <Card style={{ width: "50%" }}>v1</Card>
        {/* <Card>v2</Card> */}
      </div>
      <Button onClick={handleChangeSiteVersion}>
        שנה גרסת אתר - {siteVersion === 2 ? "גרסה ראשונה" : "גרסה שנייה"}
      </Button>
    </div>
  );
};

export default SiteVersionSettings;
