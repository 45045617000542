import { FC, memo } from "react";
import "./NavBar.css";
import SiteNav from "./SiteNav/SiteNav";
import { Language } from "../../types/types";
import DepartmentsNav from "./Departments/DepartmentsNav";
import { screenSizes } from "../UI/index.hooks";

type Props = {
  language: Language;
};

const NavBar: FC<Props> = ({ language }) => {
  return (
    <header>
      <SiteNav />
      {window.innerWidth >= screenSizes.sm && <DepartmentsNav language={language} />}
    </header>
  );
};

export default memo(NavBar);
