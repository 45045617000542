import { Flex, Modal } from "../../../UI";
import AccessabilityActionBox from "../../AccessabilityActionBox/AccessabilityActionBox";
import { ContactIcon, PasswordShowIcon, UserIcon } from "../../../UI/Icons";
import LegalPolicy from "../../../../pages/Legal/LegalPolicy";
import useCustomContext from "../../../../hooks/useCustomContext/useCustomContext";
import { useState } from "react";
import accessibilityStatementData from "../../../../pages/Legal/AccessibilitPolicy/v1";

interface IProps {
  handleRemove: () => void;
}

const AccessabilityFooter = ({ handleRemove }: IProps) => {
  const {
    user: { language },
  } = useCustomContext();

  const [isAccessabilityModalOpen, setIsAccessabilityModalOpen] = useState(false);
  return (
    <section>
      <Flex justify="space-around">
        <AccessabilityActionBox
          onClick={handleRemove}
          content="הסר נגישות"
          icon={<UserIcon color="var(--white)" size="30px" />}
        />
        <AccessabilityActionBox
          onClick={() => setIsAccessabilityModalOpen(true)}
          content="הצהרת נגישות"
          icon={<PasswordShowIcon color="var(--white)" size="30px" />}
        />
        <AccessabilityActionBox
          onClick={() => 2}
          content="שליחת משוב"
          icon={<ContactIcon color="var(--white)" size="30px" />}
        />
      </Flex>
      <Modal
        visible={isAccessabilityModalOpen}
        onCancel={() => setIsAccessabilityModalOpen(false)}
        language={language}
        title={accessibilityStatementData[language].title}
        withFooter={false}
      >
        <LegalPolicy source="accessibility" skipTitle />
      </Modal>
    </section>
  );
};

export default AccessabilityFooter;
