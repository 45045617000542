import { ProductDataType, StoreEn } from "../../../types/types";
import { screenSizes } from "../../UI/index.hooks";
import { FilterOptionsState } from "./types";

// better use it than undefined
export const productsFilterInitialState: FilterOptionsState = {
  promotionsOnlyRegular: false,
  promotionsOnlyClub: false,
  minPrice: 0,
  maxPrice: 99999,
  brands: {},
  searchInput: "",
  isOpen: window.innerWidth <= screenSizes.sm ? false : true,
  segments: {},
};

export const initFiltersState = (products: ProductDataType[], store: StoreEn): FilterOptionsState => {
  let minPrice = 9999;
  let maxPrice = -1;
  const brands: Record<string, number> = {};
  const segments: Record<string, number> = {};

  products.forEach((p) => {
    const price = p[store].item_price;

    const brand = p[store].manufacturer_name ?? "?";
    if (brands[brand]) {
      brands[brand]++;
    } else {
      brands[brand] = 1;
    }

    const segment = p[store].segment;
    if (segments[segment]) {
      segments[segment]++;
    } else {
      segments[segment] = 1;
    }

    if (price > maxPrice) maxPrice = price;

    if (price < minPrice) minPrice = price;
  });

  return {
    isOpen: productsFilterInitialState.isOpen,
    minPrice,
    maxPrice,
    brands,
    promotionsOnlyClub: false,
    promotionsOnlyRegular: false,
    searchInput: "",
    segments,
  };
};

export const productsFilterCB = (store: StoreEn, product: ProductDataType, filterOptionsState: FilterOptionsState) => {
  // max price
  if (product[store].item_price > filterOptionsState.maxPrice) return false;
  //min price
  if (product[store].item_price < filterOptionsState.minPrice) return false;

  //segments
  const segment = product[store].segment;
  if (!filterOptionsState.segments[segment]) return false;

  //brands
  const brand = product[store].manufacturer_name;
  if (brand !== null && !filterOptionsState.brands[brand]) return false;

  const promotions = product[store].promotions;
  // normal promotions
  if (filterOptionsState.promotionsOnlyRegular && !promotions) return false;

  // club promotions (club_id = 1 is using store club card , checked on victory, mahsane , shufersal)
  //todo: understand the rest of club_id types
  if (filterOptionsState.promotionsOnlyClub && !promotions?.find((p) => p.club_id?.includes(1))) return false;

  // search input
  if (!product[store].item_name.includes(filterOptionsState.searchInput)) return false;

  return true;
};
