import React from "react";
import { Drawer } from "../../UI";
import Cart from "../Cart";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import CartHeader from "../CartHeader/CartHeader";
import { useCartStore } from "../../../Zustand";

const DrawerCart = () => {
  const {
    onlineStores: { favoriteStore },
  } = useCustomContext();
  const { isCartOpen, setIsCartOpen } = useCartStore();
  return (
    <Drawer
      customHeader={<CartHeader store={favoriteStore} onClose={() => setIsCartOpen(false)} />}
      isOpen={isCartOpen}
      placement={"left"}
      onClose={() => setIsCartOpen(false)}
    >
      <div style={{ height: "calc(100vh - var(--nav-height) - 100px)", minWidth: 320 }}>
        <Cart showHeader={false} />
      </div>
    </Drawer>
  );
};

export default DrawerCart;
