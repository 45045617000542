import { FC } from "react";
import { iconClassName, IconCustom } from "./types";

const DragAndDropIcon: FC<IconCustom> = ({ clickable, cssClass, color = "var(--white)", size = 24, onClick }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill={color}
      onClick={onClick}
      className={iconClassName(clickable, cssClass)}
      style={{ rotate: "90deg" }}
    >
      {/* Top Triangle */}
      <polygon points="12,2 16,6 8,6" />

      {/* Middle Rectangles with symmetrical gaps */}
      <rect x="5" y="9" width="14" height="2" />
      <rect x="5" y="12" width="14" height="2" />
      <rect x="5" y="15" width="14" height="2" />

      {/* Bottom Triangle */}
      <polygon points="12,24 16,20 8,20" />
    </svg>
  );
};

export default DragAndDropIcon;
