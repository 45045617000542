import { FC, memo } from "react";
import { iconClassName, IconCustom } from "../types";

const PasswordShowIcon: FC<IconCustom> = ({
  color = "currentColor",
  size = "1em",
  onClick,
  clickable,
  cssClass = "",
}) => {
  return (
    <svg
      onClick={onClick}
      width={size}
      className={iconClassName(clickable, cssClass)}
      height={size}
      viewBox="0 0 24 24"
      fill={"var(--white)"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
      <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
      <g id="SVGRepo_iconCarrier">
        {" "}
        <path
          d="M1 12C1 12 5 4 12 4C19 4 23 12 23 12"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>{" "}
        <path
          d="M1 12C1 12 5 20 12 20C19 20 23 12 23 12"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></path>{" "}
        <circle
          cx="12"
          cy="12"
          r="3"
          stroke={color}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        ></circle>{" "}
      </g>
    </svg>
  );
};

export default memo(PasswordShowIcon);
