import { useState, useReducer, useCallback, FC, Dispatch, SetStateAction, useEffect } from "react";
import { Button, InfoBox } from "../../UI";
import serverAPI from "../../../api/server";
import { AuthenticationFormReducer, AuthenticationInitialState } from "../AuthReducer/AuthReducer";
import "../Authentication.css";
import { dictAuth } from "../dictionary";
import PersonalData from "./tabs/PersonalData";
import { calculateStrength } from "./PasswordStrengthMeter/PasswordStrengthMeter";
import { isValidIsraelPhoneNumber, parseCookies } from "../../../utils/utils";
import { AuthType } from "../Authentication";
import { passwordStrengthValue } from "../../../utils/constants";
import { CookieName } from "../../../types/types";
import { InfoBoxType } from "../../UI/InfoBox/InfoBox";

const language = "hebrew";

type Props = {
  setAuthType: Dispatch<SetStateAction<AuthType>>;
  handleClose: () => void;
};

const Register: FC<Props> = ({ setAuthType, handleClose }) => {
  const [formState, dispatch] = useReducer(AuthenticationFormReducer, AuthenticationInitialState);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState<{ type: InfoBoxType; content: string }>({
    type: "gray",
    content: "",
  });

  const handleRegister = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { confirmPassword, ...payload } = formState;

      const cookie = parseCookies(CookieName.Freeze);

      if (cookie) {
        setResponseMessage({ content: "please check in mail", type: "info" });
        return;
      }

      const res = await serverAPI("users/register", { ...payload, city: payload.city });
      if (res.statusCode === 201) {
        setResponseMessage({ content: res.data.message, type: "success" });
        // handleClose();
      } else if (res.statusCode === 409) {
        console.log(res);
        throw new Error(res.data);
      } else {
        throw new Error(res.data);
      }
    } catch (error: any) {
      setResponseMessage({ type: "error", content: `${error.message}` });
    } finally {
      setLoading(false);
    }
  };

  const validatePersonalData = useCallback(() => {
    if (formState.phoneNumber && !isValidIsraelPhoneNumber(formState.phoneNumber)) return false;
    else if (calculateStrength(formState.password) < passwordStrengthValue) return false;
    else if (formState.password !== formState.confirmPassword) return false;
    return true;
  }, [formState]);

  useEffect(() => {
    if (responseMessage.content) {
      setResponseMessage({ type: "gray", content: "" });
    }
    // eslint-disable-next-line
  }, [formState]);

  return (
    <form onSubmit={handleRegister} className="auth-card-wrapper auth-card-wrapper_register">
      <p className="auth-card-subtitle">{dictAuth["registerMeetingMessage"].hebrew}</p>
      <PersonalData formState={formState} language={language} dispatch={dispatch} />

      {responseMessage.content ? (
        <InfoBox
          language={language}
          content={responseMessage.content}
          type={responseMessage.type}
          cssClass="response-message"
        />
      ) : (
        <div className="space-height-md" />
      )}
      <div className="space-height-lg" />

      <Button htmlType="submit" loading={loading} disabled={!validatePersonalData()}>
        {dictAuth.register[language]}
      </Button>

      <p className="redirect-to" onClick={() => setAuthType("login")}>
        {dictAuth.alreadyMember[language]}
      </p>
    </form>
  );
};

export default Register;
