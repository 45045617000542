import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useState } from "react";
import { User, OnlineStoresType, CookieName } from "../../types/types";
import { parseCookies } from "../../utils/utils";
import useLocalStorage, { localStorageKeys } from ".././useLocalStorage";
import { getUserInitialValue, onlineStoresInitialValue, ThemeInitialValue } from "./helpers/initialValues";
import { ThemeType } from "../../components/Accessability/AccessabilityContent/AccesabilityContentOptions/AccessabilityOption";

// export type themeType = "theme-dark" | "theme-high-contrast" | "theme-light";

export interface SiteContextType {
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
  children?: JSX.Element;
  siteVersion: 1 | 2;
  setSiteVersion: Dispatch<SetStateAction<1 | 2>>;
  theme: ThemeType;
  setTheme: Dispatch<SetStateAction<ThemeType>>;
  setIsShoppingListOpen: Dispatch<SetStateAction<boolean>>;
  isShoppingListOpen: boolean;
  onlineStores: OnlineStoresType;
  setOnlineStores: Dispatch<SetStateAction<OnlineStoresType>>;
}

const token = parseCookies(CookieName.AuthUserData);

export const SiteContext = createContext<SiteContextType>({} as SiteContextType);

export const SiteProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User>(getUserInitialValue(token));
  const [isShoppingListOpen, setIsShoppingListOpen] = useState(false);
  const [siteVersion, setSiteVersion] = useState<1 | 2>(2);

  const [theme, setTheme] = useLocalStorage(localStorageKeys.AccessabilityOptions, ThemeInitialValue);
  const [onlineStores, setOnlineStores] = useLocalStorage(localStorageKeys.userOnlineStores, onlineStoresInitialValue);

  return (
    <SiteContext.Provider
      value={{
        user,
        setUser,
        siteVersion,
        setSiteVersion,
        theme,
        setTheme,
        setIsShoppingListOpen,
        isShoppingListOpen,
        onlineStores,
        setOnlineStores,
      }}
    >
      {children}
    </SiteContext.Provider>
  );
};

const useCustomContext = () => useContext(SiteContext);
export default useCustomContext;
