import { useState } from "react";
import { StoreEn } from "../../../../../types/types";
import { usePageComparisonSettings } from "../../../../../Zustand";
import useCustomContext from "../../../../../hooks/useCustomContext/useCustomContext";
import { Flex } from "../../../../../components/UI";
import { dictStores } from "../../../../../dictionary/dictStores";

const DragAndDropStoresToCompareOption = () => {
  const [dragging, setDragging] = useState<StoreEn | null>(null);
  const [dragOver, setDragOver] = useState<StoreEn | null>(null);
  const { storesToCompare, setStoresToCompare } = usePageComparisonSettings();
  const {
    user: { language },
  } = useCustomContext();

  const handleDragStart = (e: any, store: StoreEn) => {
    setDragging(store);
  };

  const handleDragOver = (e: any, store: StoreEn) => {
    e.preventDefault();
    setDragOver(store);
  };

  const handleDragEnd = (e: any) => {
    if (dragging && dragOver) {
      const newItems = [...storesToCompare];
      const draggingIndex = newItems.indexOf(dragging);
      const dragOverIndex = newItems.indexOf(dragOver);
      [newItems[draggingIndex], newItems[dragOverIndex]] = [newItems[dragOverIndex], newItems[draggingIndex]];
      setStoresToCompare(newItems);
    }
    setDragging(null);
    setDragOver(null);
  };

  return (
    <Flex dataTour="dd_carts_stores" gap={15} responsive={{ sm: { flexDirection: "column" } }}>
      {storesToCompare.map((s) => (
        <div
          key={s}
          draggable={true}
          onDragStart={(e) => handleDragStart(e, s)}
          onDragOver={(e) => handleDragOver(e, s)}
          onDragEnd={handleDragEnd}
          className="dd-box"
        >
          {dictStores[s][language]}
        </div>
      ))}
    </Flex>
  );
};

export default DragAndDropStoresToCompareOption;
