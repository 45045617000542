import { FC } from "react";
import { iconClassName, IconCustom } from "../types";

const ErrorIcon: FC<IconCustom> = ({ color = "red", size = "2rem", clickable, cssClass, onClick }) => {
  return (
    <svg
      onClick={onClick}
      className={iconClassName(clickable, cssClass)}
      fill={color}
      width={size}
      height={size}
      viewBox="-1.7 0 20.4 20.4"
    >
      <g strokeWidth="0"></g>
      <g strokeLinecap="round" strokeLinejoin="round"></g>
      <g>
        <path d="M16.417 10.283A7.917 7.917 0 1 1 8.5 2.366a7.916 7.916 0 0 1 7.917 7.917zm-6.804.01 3.032-3.033a.792.792 0 0 0-1.12-1.12L8.494 9.173 5.46 6.14a.792.792 0 0 0-1.12 1.12l3.034 3.033-3.033 3.033a.792.792 0 0 0 1.12 1.119l3.032-3.033 3.033 3.033a.792.792 0 0 0 1.12-1.12z"></path>
      </g>
    </svg>
  );
};

export default ErrorIcon;
