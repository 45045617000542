import { CSSProperties, InputHTMLAttributes, ReactNode, useCallback } from "react";
import "./Input.css";
import { CheckMark } from "../../Icons";

export type InputRule = {
  onOkMessage?: string | ReactNode;
  onFalseMessage: string | ReactNode;
  isOk: boolean;
  style?: CSSProperties;
};

interface IProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  placeholder?: string;
  id?: string;
  icon?: ReactNode;
  validate?: (value: any) => boolean | InputRule[];
  className?: string;
}

const InputUI = ({ label, icon, validate, id, className, placeholder, ...rest }: IProps) => {
  const drawValidator = useCallback(() => {
    if (!rest.onChange) throw new Error("Validator based on controlled input ");

    if (!validate) return null;
    if (!rest.value) return null;
    const result = validate(rest.value);

    if (typeof result === "boolean") return null;

    return (
      <div className={`form__message`}>
        {result.map((r, i) => {
          return (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 2,
                margin: "0 3px",
                color: r.isOk ? "green" : "red",
                ...r.style,
              }}
              key={i}
            >
              {r.isOk && r.onOkMessage ? (
                <>
                  <span>
                    {" "}
                    <CheckMark /> &nbsp;
                  </span>
                  <span>{r.onOkMessage}</span>
                </>
              ) : (
                <>
                  <span> &times; &nbsp;</span>
                  <span>{r.onFalseMessage}</span>
                </>
              )}
            </div>
          );
        })}
      </div>
    );
  }, [rest.value, validate, rest.onChange]);

  return (
    <div>
      <div className={`form-input-group`}>
        <input
          placeholder={placeholder ? placeholder : ""}
          id={id}
          name={id}
          className={`form__input ${className ?? ""} ${placeholder ? "form_input_placeholder_include" : ""}`}
          // ! Keep rest the last
          {...rest}
        />
        <label htmlFor={id} className="form__label">
          {label}
        </label>
        {icon && <div className="form__icon">{icon}</div>}
      </div>
      {drawValidator()}
    </div>
  );
};

export default InputUI;
