import { FC, useCallback, useState } from "react";
import "./CartItem.css";
import { Carousel, Divider, ImageComponent } from "../../UI";
import { PlusOrMinusIcon } from "../../UI/Icons";
import { ProductDataType, StoreEn } from "../../../types/types";
import SelectedItem from "../../SelectedItem/SelectedItem";
import NotFoundItem from "./NotFoundItem/NotFoundItem";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import PriceCalculator from "../../../models/ItemPriceCalculator/PriceCalculator";
import { resolveRamiImgUrl } from "../../../pages/Products/ProductsV2/fetch_products_v2";
import { useCartStore } from "../../../Zustand";
import { comparisonIndicators } from "../../../pages/CartComparisonPage/Helpers/Ui";

export type ItemFeatureType = "normal" | "comparison_cart";

type Props = {
  productProp: ProductDataType;
  amount: number;
  item_code: string;
  selectedStore: StoreEn;
  featureName?: ItemFeatureType;
  cssClass?: string;
};

// * Cart Item references
// * 1. In Cart
// * 2. In Cart Comparison Page

type CartItemComponent = FC<Props> & {
  DrawProduct: (args: DrawProductArgs) => JSX.Element;
  DrawItemCartActions: (args: DrawItemCartActions) => JSX.Element;
};

const CartItem: CartItemComponent = ({
  productProp,
  amount,
  item_code,
  selectedStore,
  featureName = "normal",
  cssClass,
}) => {
  const {
    user: { language },
    onlineStores: { favoriteStore, otherStores },
    siteVersion,
  } = useCustomContext();

  const { cart, updateCartItem } = useCartStore();

  const [stores] = useState(siteVersion === 1 ? [favoriteStore, ...otherStores] : [favoriteStore]);
  const [productState, setProductState] = useState(productProp);

  const [openSelected, setOpenSelected] = useState(false);

  const handleChangeAmount = (type: "minus" | "plus") => {
    const amount = type === "minus" ? -1 : 1;
    updateCartItem(item_code, amount);
  };

  const drawScoringIcon = useCallback(() => {
    switch (productState[selectedStore]?.source) {
      case "auto-code":
        return comparisonIndicators.perfect.icon;

      case "auto-similarity": //user products table should be here also
        return comparisonIndicators.similar.icon;

      case "FE-user-select":
        return comparisonIndicators.custom.icon;
      default:
        // default is unknown or user products table
        const otherUser = parseInt(productState[selectedStore]?.source || "-1");
        if (otherUser !== -1) {
          return comparisonIndicators.similar.icon;
        }

        // TODO: VERSION 1 not ready yet for this source logic
        console.log(productState[selectedStore]?.source);
        return <div>todo </div>;
    }
  }, [productState, selectedStore]);

  const drawProductInCart = useCallback(
    (store: StoreEn) => {
      if (!productState[store]) return null;
      if (store === "rami") {
        productState["rami"].img_url = resolveRamiImgUrl(productState.rami.img_url);
      }
      return CartItem.DrawProduct({
        boldString: productState[store].item_name,
        onClick: () => setOpenSelected(true),
        store: store,
        imgNode: (
          <ImageComponent
            loading="lazy"
            src={productState[store].img_url}
            alt={"cart item " + productState[store].item_name}
            onErrorSize="small"
            width={45}
            height={45}
          />
        ),
        weakNode: (
          <>
            <span>{productState[store].item_price}</span>
            <span>&#8362;</span>
            <span> </span>
            <span>ל - </span>
            <span> </span>
            <span>{productState[store].unit_qty}</span>
          </>
        ),
      });
    },
    [productState]
  );

  // TODO : Consider to remove it
  const drawCarousel = useCallback(() => {
    return (
      <Carousel showBtnOnHover rightArrow={false}>
        {stores.map((s) => {
          return drawProductInCart(s);
        })}
      </Carousel>
    );
  }, [drawProductInCart, stores]);

  const getItemPrice = useCallback(() => {
    if (siteVersion === 1) {
      return PriceCalculator.getItemPriceRange({ stores, cart, item: { amount, product: productState } });
    }
    return PriceCalculator.getItemPriceToString(selectedStore, { product: productState, amount }, cart);
  }, [amount, productState, cart, siteVersion, selectedStore, stores]);

  const handleDrawProductInCart = useCallback(() => {
    switch (featureName) {
      case "normal":
        return siteVersion === 1 ? drawCarousel() : drawProductInCart(selectedStore);
      case "comparison_cart":
        // skipping carousel view because we splitting the cart.
        return drawProductInCart(selectedStore);
      default:
        throw new Error("problem");
    }
  }, [siteVersion, drawCarousel, drawProductInCart, featureName, selectedStore]);

  if (!productState[selectedStore] && !cart[item_code].product[selectedStore]) {
    return (
      <NotFoundItem
        favoriteItemName={productState[favoriteStore].item_name}
        store={selectedStore}
        product={productState}
        setProduct={setProductState}
        language={language}
      />
    );
  }

  return (
    <div className={`cart-item-card ${cssClass ?? ""}`}>
      <div className="cart-product-wrapper">
        {handleDrawProductInCart()}
        {featureName === "comparison_cart" && selectedStore !== favoriteStore
          ? drawScoringIcon()
          : CartItem.DrawItemCartActions({
              handleAction: handleChangeAmount,
              amount,
              color: "var(--primary-color)",
              calculatedPrice: getItemPrice(),
            })}
      </div>
      <Divider margin="0" />
      {openSelected && (
        <SelectedItem
          isOpen={openSelected}
          language={language}
          setIsOpen={setOpenSelected}
          selectedProductProp={productState}
          setSelectedProductProp={setProductState}
          stores={stores}
          featureType={featureName}
          selectedStore={selectedStore}
        />
      )}
    </div>
  );
};

// Cart Item helpers

type DrawProductArgs = {
  store: StoreEn;
  onClick: () => void;
  boldString: string;
  weakNode: React.ReactNode;
  imgNode: React.ReactNode;
};

CartItem.DrawProduct = ({ store, onClick, boldString, weakNode, imgNode }: DrawProductArgs) => {
  return (
    <div className="cart-item-details_wrapper" key={store} onClick={onClick}>
      <div className="cart-item-details">
        <div>
          <b>{boldString}</b>
        </div>
        <div className="weak">{weakNode}</div>
      </div>
      <div className="cart-item-img">{imgNode}</div>
    </div>
  );
};

type DrawItemCartActions = {
  handleAction?: (action: "plus" | "minus") => void;
  color: string;
  calculatedPrice: string | number;
  amount: number;
};

CartItem.DrawItemCartActions = ({ amount, handleAction, color, calculatedPrice }: DrawItemCartActions) => {
  return (
    <div className="cart-item-buttons-wrapper">
      <div className="cart-item-buttons">
        <div className="cart-item-amount-btn" onClick={() => handleAction && handleAction("plus")}>
          <PlusOrMinusIcon color={color} sign="plus" size={"1rem"} clickable={!!handleAction} />
        </div>
        <div className="item-amount-unit-wrapper">{amount}</div>
        <div className="cart-item-amount-btn" onClick={() => handleAction && handleAction("minus")}>
          <PlusOrMinusIcon color={color} sign="minus" size={"1rem"} clickable={!!handleAction} />
        </div>
      </div>
      <div className="cart-item-price">
        <span>{calculatedPrice}</span>
        <span>&#8362;</span>
      </div>
    </div>
  );
};

export default CartItem;
