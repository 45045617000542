import { FC, useEffect, useState } from "react";
import { Button, CarouselFull, Flex, Tooltip } from "../../../../../components/UI";
import ProgressBar from "../../../../../components/UI/Progress/Progress";
import ProductCard from "../../../../../components/ProductCard/ProductCard";
import CartItem from "../../../../../components/Cart/CartItem/CartItem";
import "./CartCompareSingleItemView.css";
import Typography from "../../../../../components/UI/Typography/Typography";
import { dictStores } from "../../../../../dictionary/dictStores";
import dict from "../../../CartCompariosnViews/CartCompareSingelItemView/CartCompareSingleItemDict";
import { StoreEn } from "../../../../../types/types";
import useCustomContext from "../../../../../hooks/useCustomContext/useCustomContext";
import { useCartStore } from "../../../../../Zustand";
import PriceCalculator from "../../../../../models/ItemPriceCalculator/PriceCalculator";

interface IProps {
  handleSelectStore: (store: StoreEn) => void;
  storesToCompare: StoreEn[];
}

const CartCompareSingleItemView: FC<IProps> = ({ handleSelectStore, storesToCompare }) => {
  const {
    user: { language },
    onlineStores: { favoriteStore, otherStores },
  } = useCustomContext();
  const { cart } = useCartStore();
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [cartKeys, setCartKeys] = useState(Object.keys(cart));
  const [currentItemCode, setCurrentItemCode] = useState(cartKeys[carouselIndex]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [renderCartSummarize, setRenderCartSummarize] = useState(false);

  useEffect(() => {
    setCurrentItemCode(cartKeys[carouselIndex]);
  }, [carouselIndex, cart, cartKeys]);

  useEffect(() => {
    if (!cart[currentItemCode]) {
      setCartKeys(Object.keys(cart));
    }
  }, [cart, currentItemCode]);

  const drawCartItemsFromOtherStores = () => {
    if (!cart[currentItemCode]) {
      return <div>הפריט הוסר מהעגלה</div>;
    }
    return storesToCompare.map((s) => {
      const match = cart[currentItemCode]?.product[s]?.source;
      const cssClass = match ? "cart_item_store_tag_match" : "cart_item_store_tag_not_match";
      return (
        <div key={`${s}_${currentItemCode}`} className="cart_item_wrapper">
          <div className={cssClass}>{dictStores[s][language]}</div>
          <CartItem
            amount={cart[currentItemCode].amount}
            featureName="comparison_cart"
            selectedStore={s}
            productProp={cart[currentItemCode].product}
            item_code={currentItemCode}
            cssClass="small-cart-item"
          />
        </div>
      );
    });
  };

  const drawCheckOutStoresBtn = () => {
    return [favoriteStore, ...otherStores].map((s, i) => {
      return (
        <Button onClick={() => handleSelectStore(s)} key={i} variant={i % 2 === 0 ? "primary" : "contrast"}>
          {dictStores[s][language]} - {PriceCalculator.getTotalCartCost(s, cart).toFixed(2)} ₪
        </Button>
      );
    });
  };

  return (
    <div className="cart-compare-single-item-view_wrapper">
      <div className="cart-compare-single-item-view_top cart-compare-section-box">
        <div className="flex-row gap-10">
          <Tooltip placement="bottom" title={dict.info[language]} />
          <Typography.Title style={{ fontSize: "16px" }} level={5}>
            {dictStores[favoriteStore][language]}
          </Typography.Title>
        </div>
        <div className="cart-compare-single-item-view_top_carousel">
          <CarouselFull
            gap={4}
            onSliding={(currentIdx) => {
              setCarouselIndex(currentIdx);
            }}
            slideToShow={cartKeys.length > 3 ? 3 : 1}
          >
            {Object.values(cart).map((item) => (
              <ProductCard
                key={item.product[favoriteStore].item_code}
                item_code={currentItemCode}
                productData={item.product}
                version={2}
                cssClass="cart-compare-single-item-view_product_card_wrapper"
              />
            ))}
          </CarouselFull>
        </div>
        {isCompleted ? (
          <Button
            style={{ minWidth: "fit-content" }}
            width={"30%"}
            size="small"
            variant="contrast"
            onClick={() => setRenderCartSummarize((p) => !p)}
          >
            {renderCartSummarize ? "Compare items" : "Summarize Carts"}
          </Button>
        ) : (
          <ProgressBar
            stopOnComplete
            onCompleted={() => {
              setIsCompleted(true);
            }}
            barColor="var(--primary-color)"
            height={"15px"}
            width={"50%"}
            value={(100 * carouselIndex) / (cartKeys.length - 1)}
          />
        )}
      </div>
      <b style={{ textAlign: "center" }}> {dict.otherStores[language]}</b>
      <div className="cart-compare-single-item-view_bottom cart-compare-section-box">
        {!renderCartSummarize ? (
          <div className="cart-compare-single-item-view_bottom_otherStores_wrapper scrollable">
            {drawCartItemsFromOtherStores()}
          </div>
        ) : (
          <div style={{ height: "90%", overflow: "auto", maxWidth: "65vw", margin: "auto" }}>
            <Flex gap={10} flexDirection="column">
              {drawCheckOutStoresBtn()}
            </Flex>
          </div>
        )}
      </div>
    </div>
  );
};

export default CartCompareSingleItemView;
