import "./SiteNavOptions.css";
import LanguageSelect from "./LanguageSelect/LanguageSelect";
import UserAuthSelect from "./UserAuthSelect/UserAuthSelect";
import { FC, ReactNode } from "react";
import { IconCustom } from "../../../UI/Icons/types";
import ShoppingList from "./ShopingList/ShopingList";
import CartTogglePrimary from "./CartTogglePrimary/CartTogglePrimary";
import { Typography } from "../../../UI";
import useCustomContext from "../../../../hooks/useCustomContext/useCustomContext";
import { useCartStore } from "../../../../Zustand";

const SiteNavOptions = ({ isHamburgerOpen }: { isHamburgerOpen?: boolean }) => {
  const { setUser, user } = useCustomContext();
  const { cart } = useCartStore();
  const cartLength = Object.keys(cart).length;

  return (
    <>
      <li className={`site_nav_box ${isHamburgerOpen ? "hamburger-open" : "hamburger-close"} anim-fade-in`}>
        <ShoppingList />
      </li>

      <li className={`site_nav_box ${isHamburgerOpen ? "hamburger-open" : "hamburger-close"} anim-fade-in`}>
        <LanguageSelect language={user.language} setUser={setUser} />
      </li>
      <li className={`site_nav_box ${isHamburgerOpen ? "hamburger-open" : "hamburger-close"} anim-fade-in`}>
        <UserAuthSelect fullName={user.fullName} setUser={setUser} isUser={user.isUser} cartLength={cartLength} />
      </li>
      <li
        className={`site_nav_box responsive-hidden ${
          isHamburgerOpen ? "hamburger-open" : "hamburger-close"
        } anim-fade-in`}
      >
        <CartTogglePrimary />
      </li>
    </>
  );
};

export default SiteNavOptions;

interface ISiteNavOptionTitle {
  onClick?: () => void;
  Icon?: FC<IconCustom>;
  customIcon?: ReactNode;
  label: string | ReactNode;
  width?: number;
  iconProps?: IconCustom;
}

export const SiteNavOptionsTitle: FC<ISiteNavOptionTitle> = ({
  onClick,
  Icon,
  label,
  customIcon,
  width = 100,
  iconProps,
}) => {
  return (
    <div className="site_nav_option_title" onClick={() => onClick && onClick()}>
      {customIcon && customIcon}
      {Icon && (
        <Icon size="1.5rem" color={window.innerWidth < 576 ? "var(--white)" : "var(--primary-color)"} {...iconProps} />
      )}
      <Typography color="var(--primary-color)" align="center" ellipsis className="responsive-hidden-lg">
        {label}
      </Typography>
    </div>
  );
};
