import { memo, useCallback, useEffect, useMemo } from "react";
import CartContent from "./CartContent/CartContent";
import { CartStorage } from "../../types/types";
import useLocalStorage, { localStorageKeys } from "../../hooks/useLocalStorage";
import useCustomContext from "../../hooks/useCustomContext/useCustomContext";
import PriceCalculator from "../../models/ItemPriceCalculator/PriceCalculator";
import { useCartStore } from "../../Zustand";

const Cart = ({ showHeader = true }: { showHeader?: boolean }) => {
  const {
    user: { language },
    onlineStores: { favoriteStore, otherStores },
    siteVersion,
  } = useCustomContext();
  const { cart } = useCartStore();
  const stores = useMemo(
    () => (siteVersion === 1 ? [favoriteStore, ...otherStores] : [favoriteStore]),
    [siteVersion, favoriteStore, otherStores]
  );
  const [, setStorageCart] = useLocalStorage(localStorageKeys.cart, []);

  const handleCartPriceRange = useCallback(() => {
    if (siteVersion === 1) {
      return PriceCalculator.getCartCostRange({ stores, cart });
    }
    return PriceCalculator.getTotalCartCost(favoriteStore, cart).toFixed(2);
  }, [cart, siteVersion, favoriteStore, stores]);

  const handleCartStorage = () => {
    const products = Object.values(cart);
    const productsToStore: CartStorage[] = [];
    products.forEach((p) => {
      const storage: any = {};
      storage.amount = p.amount;
      stores.forEach((store) => {
        storage[store] = p.product[store].item_code;
      });
      productsToStore.push(storage);
    });
    setStorageCart(productsToStore);
  };

  useEffect(() => {
    handleCartStorage();
    // eslint-disable-next-line
  }, [cart]);

  return (
    <CartContent
      showHeader={showHeader}
      // cart={cart}
      favoriteStore={favoriteStore}
      cartTotalPriceRange={handleCartPriceRange()}
      language={language}
    />
  );
};

export default memo(Cart);
