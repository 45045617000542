import { usePageComparisonSettings } from "../../../../../Zustand";
import { Checkbox, Flex } from "../../../../../components/UI";
import { dictStores } from "../../../../../dictionary/dictStores";
import useCustomContext from "../../../../../hooks/useCustomContext/useCustomContext";

const StoresToCompareOption = () => {
  const { toggleCheckedStore, storesToCompare } = usePageComparisonSettings();
  const {
    user: { language },
    onlineStores: { otherStores },
  } = useCustomContext();

  return (
    <Flex dataTour="select_stores" responsive={{ sm: { flexDirection: "column" } }}>
      {otherStores.map((s) => {
        return (
          <Checkbox
            // dataTour={"option-other-store-checkbox"}
            key={s}
            label={dictStores[s][language]}
            checked={storesToCompare.includes(s)}
            disabled={storesToCompare.length === 1 && storesToCompare.includes(s)}
            onChange={() => toggleCheckedStore(s)}
          />
        );
      })}
    </Flex>
  );
};

export default StoresToCompareOption;
