import { useLocation } from "react-router-dom";
import { useCartStore } from "../../../../../Zustand";
import { CartIcon } from "../../../../UI/Icons";
import "./CartTogglePrimary.css";

const CartTogglePrimary = () => {
  const { cart, setIsCartOpen } = useCartStore();
  const location = useLocation();
  // console.log(location.pathname);
  if (location.pathname === "/cart/compare") return null;

  return (
    <div onClick={() => setIsCartOpen((p) => !p)} className="flex-center cart-toggle-primary-wrapper">
      <CartIcon clickable size="75%" color="white" />
      {Object.keys(cart).length > 0 && (
        <div className="cart-toggle-primary-float flex-center">{Object.keys(cart).length}</div>
      )}
    </div>
  );
};

export default CartTogglePrimary;
