import PriceCalculator from "../../../models/ItemPriceCalculator/PriceCalculator";
import { Cart, StoreEn } from "../../../types/types";

// filter null value on storeToCompare from the original cart
const filterAndGenerateComparableFakeCart = (originalCart: Cart, storeToCompare: StoreEn) => {
  const cartItems = Object.keys(originalCart);
  const fakeCart: Cart = {};
  cartItems.forEach((itemCode) => {
    if (originalCart[itemCode].product[storeToCompare]) {
      fakeCart[itemCode] = originalCart[itemCode];
    }
  });
  return fakeCart;
};

/**
 Return normalizing favorite cart price based on other store by removing from favorite items that not exist on those store
 * 
*/
export const getNormalizingStorePrice = (
  storeToCompare: StoreEn,
  favoriteStore: StoreEn,
  cart: Cart,
  type?: "string" | "number"
) => {
  // removing products that not exist in normalizingStore
  const fakeFavoriteCart: Cart = filterAndGenerateComparableFakeCart(cart, storeToCompare);
  const fakeCartTotalPrice = PriceCalculator.getTotalCartCost(favoriteStore, fakeFavoriteCart);
  if (type === "number") return fakeCartTotalPrice;
  return fakeCartTotalPrice.toFixed(2);
};

/**
 Get the missing items on current store compare to favorite store
 * */
export const getMissingItems = (cart: Cart, favoriteStore: StoreEn, currentStore: StoreEn) => {
  let missing: string[] = [];
  Object.keys(cart).forEach((item) => {
    if (!cart[item].product[currentStore]) {
      missing.push(cart[item].product[favoriteStore].item_name);
    }
  });

  return missing;
};
