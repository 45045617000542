import { useEffect, useState } from "react";
import "./ProfileSetting.css";
import PrivacySettings from "./PrivacySettings/PrivacySettings";
import ShoppingHistory from "./ShoppingHistory/ShoppingHistory";
import UISettings from "./UISettings/UISettings";
import MyScore from "./MyScore/MyScore";
import { Accordion, AccordionItem, Card } from "../UI";
import SiteVersionSettings from "./SiteVersionSettings/SiteVersionSettings";
import StoresPreference from "./StorePreference/StoresPreference";
import { useNavigate, useParams } from "react-router-dom";

// tabs
// 1. פרטי התחברות
// 2. פרטי תשלום
// 3. בחירת חנות
// 4. העדפות חכמות
// 5. נראות אתר

const tabs = [
  { route: "login", idx: 0, hebrew: "פרטי התחברות", english: "login data" },
  { route: "history", idx: 1, hebrew: "היסטוריית קניות", english: "Shopping History" },
  { route: "stores", idx: 2, hebrew: "בחירת חנויות", english: "Store Select" },
  { route: "ui", idx: 3, hebrew: "מראה ונגישות", english: "UI" },
  { route: "score", idx: 4, hebrew: "הנקודות שצברתי", english: "My Score" },
  { route: "version", idx: 5, hebrew: "שינוי גרסת אתר", english: "Site version control" },
];

const ProfileSetting = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { tab } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setActiveTab(tabs.findIndex((t) => t.route === tab) || 0);
    // eslint-disable-next-line
  }, []);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
    navigate(`/profile/${tabs[index].route}`);
  };

  const generateTabs = () => {
    return tabs.map((t) => {
      return (
        <div
          key={t.english}
          className={`profile-tab ${activeTab === t.idx ? "active" : ""}`}
          onClick={() => handleTabClick(t.idx)}
        >
          {t.hebrew}
        </div>
      );
    });
  };

  if (window.innerWidth < 576) {
    const items: AccordionItem[] = [
      { label: tabs[0].hebrew, children: [<PrivacySettings />], key: "PrivacySettings" },
      { label: tabs[1].hebrew, children: [<ShoppingHistory />], key: "ShoppingHistory" },
      { label: tabs[2].hebrew, children: [<StoresPreference />], key: "StoresPreference" },
      { label: tabs[3].hebrew, children: [<UISettings />], key: "UISettings" },
      { label: tabs[4].hebrew, children: [<MyScore />], key: "MyScore" },
      { label: tabs[5].hebrew, children: [<SiteVersionSettings />], key: "SiteVersionSettings" },
    ];
    return <Accordion items={items} />;
  }

  return (
    <div className="profile-container responsive-flex-column">
      <Card radius={1} shadow={false} cssClass="profile-tab-content-tabs scrollable">
        <div className="tabs-wrapper responsive-flex-row">{generateTabs()}</div>
      </Card>
      <Card key={activeTab} radius={1} shadow={false} title={tabs[activeTab].hebrew} style={{ width: "100%" }}>
        <div className="container animation-fade-in animation-fade-in-opacity">
          {activeTab === 0 && <PrivacySettings />}
          {activeTab === 1 && <ShoppingHistory />}
          {activeTab === 2 && <StoresPreference />}
          {activeTab === 3 && <UISettings />}
          {activeTab === 4 && <MyScore />}
          {activeTab === 5 && <SiteVersionSettings />}
        </div>
        {/* Add more content components as needed */}
      </Card>
    </div>
  );
};

export default ProfileSetting;
