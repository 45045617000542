import { BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, Bar } from "recharts";
import Authentication from "../../components/Authentication/Authentication";
import Logo from "../../components/Logo/Logo";
import { Card, Flex } from "../../components/UI";
import "./Authentication.css";

const LoginImage = () => {
  const data = [
    { name: "רשת א", value1: 300, value2: 200 },
    { name: "רשת ב", value1: 400, value2: 250 },
    { name: "רשת ג", value1: 200, value2: 150 },
    { name: "רשת ד", value1: 200, value2: 150 },
    { name: "רשת ה", value1: 200, value2: 150 },
  ];

  return (
    <div className="modern-container responsive-hidden">
      <div className="content">
        <h1>ברוכים השבים</h1>
        <h2>התחברו לחשבון שלכם</h2>
        <p>עגלות דומות מחירים שונים. הגיע הזמן להשוות בין הרשתות השונות בצורה אוטומטית ונוחה שתחסוך לכם זמן וכסף.</p>
      </div>
      <Card cssClass="content-img">
        <ResponsiveContainer width="100%" height={250}>
          <BarChart width={500} height={300} data={data}>
            <Legend verticalAlign="top" />
            {/* <CartesianGrid strokeDasharray="3 3" /> */}
            <XAxis dataKey="name" />
            {/* <YAxis /> */}
            <Tooltip />
            {/* Bottom part of the bar */}
            <Bar barSize={30} dataKey="value2" stackId="a" fill="var(--secondary-color)" />
            {/* Top part of the bar */}
            <Bar barSize={30} dataKey="value1" stackId="a" fill="#d3d3d3" />
          </BarChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
};

// TODO : AuthenticationPage is hardcoded in the labels
const AuthenticationPage = () => {
  return (
    <div className="login-container2 rtl">
      <Flex justify="center" align="center" gap={20} responsive={{ sm: { flexDirection: "column" } }}>
        <Flex flexDirection="column" gap={20} responsive={{ sm: { gap: 10 } }}>
          <Logo fontSize={40} />
          <Authentication isOpen={true} type="login" isFullPage />
        </Flex>
        <LoginImage />
      </Flex>
    </div>
  );
};

export default AuthenticationPage;
