import { FC, useState, Dispatch, SetStateAction } from "react";
import { Divider } from "../../../UI";
import { Language, ProductDataType, StoreEn } from "../../../../types/types";
import CartItem from "../CartItem";
import SelectedItem from "../../../SelectedItem/SelectedItem";
import { comparisonIndicators } from "../../../../pages/CartComparisonPage/Helpers/Ui";

interface IProps {
  favoriteItemName: string;
  store: StoreEn;
  product: ProductDataType;
  setProduct: Dispatch<SetStateAction<ProductDataType>>;
  language: Language;
}

const NotFoundItem: FC<IProps> = ({ store, language, favoriteItemName, product, setProduct }) => {
  const [isOpenModal, setIsOpenModal] = useState(false);

  const DrawInitState = () => {
    return CartItem.DrawProduct({
      boldString: "לא מצאנו התאמה",
      imgNode: <div className="cart-item_not_found_in_cart_img"></div>,
      weakNode: <span>{favoriteItemName}</span>,
      onClick: () => setIsOpenModal(true),
      store: store,
    });
  };

  return (
    <div data-tour="not-found-item" className="cart-item-card small-cart-item">
      <div className="cart-product-wrapper ">
        {DrawInitState()}
        {comparisonIndicators.notFound.icon}
      </div>
      <Divider margin="0" />
      <SelectedItem
        stores={[store]}
        selectedProductProp={product}
        isOpen={isOpenModal}
        language={language}
        setIsOpen={setIsOpenModal}
        selectedStore={store}
        featureType="comparison_cart"
        setSelectedProductProp={setProduct}
      />
    </div>
  );
};

export default NotFoundItem;
