import React, { useState, useReducer, Dispatch, SetStateAction, FC } from "react";
import serverAPI from "../../../api/server";
import { Button, InputUI, InfoBox, InfoBoxType } from "../../UI";
import "../Authentication.css";
import { EmailIcon } from "../../UI/Icons";
import { AuthenticationFormReducer, AuthenticationInitialState } from "../AuthReducer/AuthReducer";
import { dictAuth } from "../dictionary";

import { AuthType } from "../Authentication";

const language = "hebrew";

type Props = {
  setAuthType: Dispatch<SetStateAction<AuthType>>;
};

const ForgetPassword: FC<Props> = ({ setAuthType }) => {
  const [formState, dispatch] = useReducer(AuthenticationFormReducer, AuthenticationInitialState);
  const [loading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState<{ text: string; type: InfoBoxType }>({
    text: "",
    type: "gray",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch({ type: name as any, payload: value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    try {
      setLoading(true);
      const res = await serverAPI("users/forget-password", { email: formState.email });
      if (res.statusCode === 200) {
        setResponseMessage({ text: dictAuth.emailSent[language], type: "success" });
        dispatch({ type: "SET_EMAIL" as any, payload: "" });

        setTimeout(() => {
          setResponseMessage({ text: dictAuth.closeWindow[language], type: "info" });
        }, 3000);
        // handleClose();
      } else {
        setResponseMessage({ text: dictAuth.userNotFound[language], type: "warning" });
      }
    } catch (error: any) {
      setResponseMessage({ text: error.message, type: "error" });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="auth-card-wrapper"
      style={{ display: "flex", flexDirection: "column", gap: 10 }}
    >
      <div className="auth-card-subtitle">{dictAuth.forgetPasswordMeetingMessage[language]}</div>
      <InputUI
        id="email"
        name="SET_EMAIL"
        required
        type="email"
        label={dictAuth.email[language]}
        value={formState.email}
        onChange={handleInputChange}
        icon={<EmailIcon />}
      />

      <Button htmlType="submit" loading={loading}>
        {dictAuth.sendResetPasswordEmail[language]}
      </Button>

      <p className="redirect-to">
        <span onClick={() => setAuthType("register")}>{dictAuth.notAMember[language]}</span>
        <span onClick={() => setAuthType("login")}>{dictAuth.alreadyAMember[language]}</span>
      </p>

      {responseMessage.text && (
        <InfoBox
          cssClass="response-message"
          content={responseMessage.text}
          type={responseMessage.type}
          language={language}
        />
      )}
    </form>
  );
};

export default ForgetPassword;
