import { FC, useCallback, useEffect, useState } from "react";
import { IconCustom } from "../Icons/types";
import { Flex } from "..";
import "./ButtonGroup.css";

/**
 * Represents a single button inside the ButtonGroup component. props here are stronger than the entire component props.
 */
export type ButtonInGroup = {
  label?: string;
  onClick: () => void;
  bgColor?: string;
  color?: string;
  dataTour?: string;
  icon?: FC<IconCustom>;
  activeColor?: string;
  hoverColor?: string;
  isDefaultActive?: true;
};

/**
 * Props for the ButtonGroup component.
 */
interface IButtonGroupProps {
  buttons: ButtonInGroup[];
  /**
   * if button include icon , it hides icons within the buttons and show on hover.
   */
  isIconHidden?: boolean;
  /**
   * if button include label , it hides labels within the buttons and show on hover.
   */
  isLabelHidden?: boolean;
  width?: string | number;
  height?: string | number;
  gap?: number;
  /**
   * In case buttonsBgColor is set and single button doesnt include bgColor - the bg color is from here
   * @default "transparent"
   * */
  buttonsBgColor?: string;
  // same as buttonsBgColor but for color
  buttonsColor?: string;
  // same as buttonsBgColor but for active
  buttonsActiveColor?: string;
  // same as buttonsBgColor but for hover
  buttonsHoverColor?: string;
  /**
   * Active button style
   * @default "bg"
   * */
  activeType?: "border" | "bg";
}

const ButtonGroup = ({
  buttons,
  isIconHidden,
  isLabelHidden,
  width,
  height = 50,
  gap,
  buttonsBgColor = "transparent",
  buttonsColor = "var(--black)",
  buttonsActiveColor,
  buttonsHoverColor,
  activeType = "bg",
}: IButtonGroupProps) => {
  const [isClicked, setIsClicked] = useState(-1);
  const [hoverIndex, setHoverIndex] = useState(-1);
  // const hoverIndex = useRef<number>(-1); // Use ref instead of state

  useEffect(() => {
    return () => {
      setIsClicked(-1);
    };
  }, []);

  const bgColor = useCallback(
    (button: ButtonInGroup, index: number) => {
      if (hoverIndex === index) {
        return {
          backgroundColor: button.hoverColor || buttonsHoverColor || button.bgColor || buttonsBgColor,
          opacity: 0.7,
        };
      }
      return { backgroundColor: button.bgColor || buttonsBgColor };
    },
    [buttonsBgColor, buttonsHoverColor, hoverIndex]
  );

  /**
   *
   * */
  const activeStyles = useCallback(
    (index: number, button: ButtonInGroup) => {
      switch (activeType) {
        case "border":
          return {
            border: `2px solid ${
              isClicked === index ? button.activeColor || buttonsActiveColor || "black" : "transparent"
            }`,
            borderColor: button.bgColor,
          };
        case "bg":
          return {
            backgroundColor:
              isClicked === index || (button.isDefaultActive && isClicked === -1)
                ? button.activeColor || buttonsActiveColor || "var(--primary-color)"
                : bgColor(button, index).backgroundColor,
          };

        default:
          return {};
      }
    },
    [buttonsActiveColor, isClicked, bgColor, activeType]
  );

  return (
    <Flex gap={gap} wrap="wrap" style={{ width, height }}>
      {buttons.map((button, index) => (
        <button
          onMouseEnter={() => setHoverIndex(index)}
          onMouseLeave={() => setHoverIndex(-1)}
          key={index}
          className="button-in-group"
          style={{
            ...bgColor(button, index),
            color: button.color || buttonsColor,
            borderRadius: gap ? 4 : 0,
            borderColor: "transparent",
            ...activeStyles(index, button),
          }}
          data-tour={button.dataTour}
          onClick={() => {
            setIsClicked(index);
            button.onClick();
          }}
        >
          <Flex align="center">
            {button.label && (
              <span
                style={{ fontSize: "1em" }}
                className={isLabelHidden && button.icon ? `button-children-toggle` : ""}
              >
                {button.label}
              </span>
            )}
            {button.icon && (
              <button.icon
                size={"1em"}
                color={button.color || buttonsColor}
                cssClass={isIconHidden && button.label ? `button-children-toggle` : ""}
              />
            )}
          </Flex>
        </button>
      ))}
    </Flex>
  );
};

export default ButtonGroup;
