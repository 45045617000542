import { useEffect, useState } from "react";

export const screenSizes = {
  sm: 576,
  md: 992,
} as const;

type ScreenSizeKey = Partial<keyof typeof screenSizes>;

export type ResponsiveStyles = Partial<Record<ScreenSizeKey, Partial<React.CSSProperties>>>;

const useResponsiveStyles = (responsive?: ResponsiveStyles) => {
  const [styles, setStyles] = useState<React.CSSProperties>({});

  useEffect(() => {
    if (!responsive) return;

    const mediaQueryLists: MediaQueryList[] = [];
    const styleMap = new Map<MediaQueryList, Partial<React.CSSProperties>>();

    // Create MediaQueryList for each breakpoint
    Object.entries(responsive).forEach(([size, rules]) => {
      const breakpoint = screenSizes[size as ScreenSizeKey];
      const mediaQuery = window.matchMedia(`(max-width: ${breakpoint}px)`);
      mediaQueryLists.push(mediaQuery);
      styleMap.set(mediaQuery, rules);
    });

    // Update styles when media queries match
    const updateStyles = () => {
      const matchedStyles: Partial<React.CSSProperties> = {};
      mediaQueryLists.forEach((mql) => {
        if (mql.matches) {
          Object.assign(matchedStyles, styleMap.get(mql));
        }
      });
      setStyles(matchedStyles);
    };

    // Attach listeners
    mediaQueryLists.forEach((mql) => {
      mql.addEventListener("change", updateStyles);
    });

    // Initial update
    updateStyles();

    // Cleanup listeners
    return () => {
      mediaQueryLists.forEach((mql) => {
        mql.removeEventListener("change", updateStyles);
      });
    };
  }, [responsive]);

  return styles;
};

export default useResponsiveStyles;
