export type StoreEn = "victory" | "mahsane" | "bitan" | "rami" | "shufersal" | "carrefour" | "quick";
export type StoreHe = "ויקטורי" | "מחסני השוק" | "יינות ביתן" | "רמי לוי" | "שופרסל" | "קרפור" | "קוויק";
export type StoreObject = { name: StoreEn; id: number };
export type StoresDict = { [id: number]: string };

export type Language = "hebrew" | "english" | "arabic" | "russian";

export type Dictionary = Record<string, Record<Language, string>>;

export type User = {
  // id: string;
  isUser: boolean;
  fullName: string;
  city: string | null;
  email: string;
  phoneNumber?: string;
  // favoriteStore: StoreEn;
  // otherStores: StoreEn[];
  // hasClubCard: StoreEn[];
  language: Language;
  password?: string;
};

// export type CartSource = "auto" | string | "users";
export type CartSource = "auto-similarity" | "auto-code" | "FE-user-select" | `${number}`;

export type CartItem = {
  amount: number;
  product: ProductDataType;
};

export type Cart = {
  [key: string]: CartItem;
};

export type SplitCart = {
  [key: string]: {
    [store in StoreEn]: PricesTable & FrontTable & { promotions: PromotionTable[] } & ProductMetaData;
  };
};

export type PricesTable = {
  item_code: string;
  item_name: string;
  item_price: number;
  price_update_date: Date | string;
  item_type: number;
  manufacturer_name: string | null;
  manufacture_country: string | null;
  manufacturer_item_description: string | null;
  unit_qty: string;
  quantity: number;
  unit_of_measure: string | null;
  b_is_weighted: number | null;
  qty_in_package: number;
  unit_of_measure_price: number;
  allow_discount: number;
  item_status: number;
  available: boolean;
  created_at?: Date | string;
  updated_at?: Date | string;
};
export type FrontTable = {
  id: number;
  retailer_product_id: string;
  item_code_front: string | null;
  external_barcode: string;
  item_code_back: string;
  item_name_front: string;
  item_name_back: string | null;
  img_url: string;
  category: string;
  sub_category: string;
  segment: string;
  created_at?: Date | string;
  updated_at?: Date | string;
};
export type PromotionTable = {
  store?: StoreEn;
  promotion_id: string;
  item_code: string;
  item_type: number;
  is_gift_item: number;
  reward_type: number;
  promotion_description: string;
  allow_multiple_discounts: number;
  additional_is_coupon: number | null;
  additional_is_total: number | null;
  additional_gift_count: number;
  additional_is_active: number | null;
  is_weighted_promo: number | null;
  promotion_start_date: Date | string;
  promotion_end_date: Date | string;
  club_id: number[] | null;
  min_qty: number;
  max_qty: number | null;
  discounted_price: number;
  discounted_price_per_mida: number;
  discount_rate: number | null;
  discount_type: number | null;
  min_no_of_items_offered: number;
  min_purchase_amount: number | null;
  weight_unit: number | null;
  promotion_update_date: Date | string;
  created_at?: Date | string;
  updated_at?: Date | string;
};

export type ProductMetaData = {
  source?: CartSource;
};

// Same as in BE
export type ProductDataType = {
  [store in StoreEn]: PricesTable & FrontTable & { promotions: PromotionTable[] } & ProductMetaData;
};

export type subCategory = {
  key: string;
  id: string;
} & {
  [lang in Language]: string;
};

export type Theme = "light" | "dark";

export type CartStorage = Partial<{ [key in StoreEn]: string }>;

export type OnlineStoresType = {
  favoriteStore: StoreEn;
  otherStores: StoreEn[];
  hasClubCard: StoreEn[];
};

// ! Get From Server - it must be sync
export enum CookieName {
  EmailFreeze = "em.ai.lfr.eez",
  AuthUserData = "uDwAs.ev.dd",
  AuthUserId = "sci.to.reg",
  Freeze = "freezer", // EmailFreeze and ContactUsFreeze are same as freeze , freeze is general
}
