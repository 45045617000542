export type IconCustom = {
  size?: string;
  isHover?: boolean;
  customColor?: string;
  color?: string;
  hovered?: boolean;
  clickable?: boolean;
  cssClass?: string;
  onClick?: () => void;
};

export const iconClassName = (clickable?: boolean, cssClass?: string) => {
  if (clickable && cssClass) return `clickable-icon ${cssClass}`;
  else if (clickable) return "clickable-icon";
  else if (cssClass) return cssClass;
  return undefined;
};
