import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTour } from "./TourContext";
import { Card, CloseBtn, Flex } from "../UI";
import "./Tour.css";
import { createPortal } from "react-dom";
import { Language } from "../../types/types";
import { resolveDirClass } from "../../utils/utils";
import { TourStep } from "./TourHelpers";

const Tour: React.FC<{ language: Language }> = ({ language }) => {
  const { steps, currentStep, isTourActive, nextStep, endTour, prevStep, setCurrentStep } = useTour();

  const ref = useRef<HTMLDivElement>(null);
  const [cardSizes, setCardSizes] = useState<{ width: number; height: number }>({ width: 150, height: 150 });
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null);
  const step = steps[currentStep];

  // if step.waitForInteraction is true
  const handleActiveInteraction = useCallback(
    (targetElementDom: HTMLElement) => {
      const originalZIndex = targetElementDom.style.zIndex;

      // Set up logic to wait for interaction (e.g., a click or other event)
      const handleInteraction = () => {
        targetElementDom.removeEventListener("click", handleInteraction);
        targetElementDom.style.zIndex = originalZIndex;
        nextStep();
      };

      targetElementDom.style.zIndex = "999";
      targetElementDom.addEventListener("click", handleInteraction);
    },
    [nextStep]
  );

  const handleObserver = useCallback(
    (observer: MutationObserver | null, runStepCb: () => void) => {
      observer = new MutationObserver(() => {
        const dynamicTarget = document.querySelector(step.target) as HTMLElement | null;

        if (dynamicTarget) {
          console.log(`Step ${currentStep + 1}: Target appeared`, dynamicTarget);
          // dynamicTarget.style.zIndex = "999";
          setTargetElement(dynamicTarget);
          observer?.disconnect(); // Stop observing
          runStepCb(); // Retry the step now that the target exists
        }
      });

      observer.observe(document.body, { childList: true, subtree: true });
      return observer;
    },
    [currentStep, step]
  );

  useEffect(() => {
    if (!isTourActive || steps.length === 0) return;
    let observer: MutationObserver | null = null;

    const runStep = () => {
      const { target, waitForInteraction, skipOnNotFoundTarget } = step;
      const targetElementDom = document.querySelector(target) as HTMLElement | null;

      if (targetElementDom) {
        // Highlight the element or show the tooltip
        console.log(`Step ${currentStep + 1}: Target found (${target})`, targetElement);

        // Scroll the target element into view
        targetElementDom.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "center",
        });

        // If interaction is required, don't move to the next step automatically
        if (waitForInteraction) {
          handleActiveInteraction(targetElementDom);
        }
        setTargetElement(targetElementDom);
      } else if (skipOnNotFoundTarget) {
        // If the target is not found and skipping is allowed, move to the next step
        console.log(`Step ${currentStep + 1}: Target not found, skipping ${target}`);
        nextStep();
      } else if (step.awaiting) {
        console.log(`Step ${currentStep + 1}: Waiting for target to appear`);
        // Observe the DOM for changes
        observer = handleObserver(observer, runStep);
      }
    };

    runStep();

    return () => {
      if (observer) {
        observer.disconnect();
        setTargetElement(null);
        // targetElementRef.current = null;
      }
    };
  }, [currentStep, isTourActive, steps, nextStep, step, targetElement, handleActiveInteraction, handleObserver]);

  useEffect(() => {
    // Measure card size after it is rendered
    if (ref.current) {
      const { width, height } = ref.current.getBoundingClientRect();
      setCardSizes({ width, height });
    }
  }, [currentStep]); // Recalculate if the step changes

  if (!isTourActive || steps.length === 0) return null;

  if (!targetElement) return null;

  const rect = targetElement.getBoundingClientRect();

  const { content, placement = "top", highlightTarget = true } = step;

  const positions = {
    top: { top: rect.top - cardSizes.height - 10, left: rect.left + rect.width / 2 - cardSizes.width / 2 },
    bottom: { top: rect.bottom + 10, left: rect.left + rect.width / 2 - cardSizes.width / 2 },
    left: {
      top: rect.top + rect.height / 2 - cardSizes.height / 2,
      right: rect.left - cardSizes.width - rect.width - 10,
    },
    right: { top: rect.top + rect.height / 2 - cardSizes.height / 2, left: rect.right + 10 },
    center: { top: "50%", left: "50%", transform: "translate(-50%,-50%)" },
  };
  const tooltipPosition = positions[placement] || positions.top;

  const tourElement = (
    <div
      className="tour-overlay"
      style={{
        transition: "clip-path ease-in 0.1s ",
        clipPath:
          placement === "center" || !highlightTarget
            ? "none"
            : `polygon(
        0% 0%, 100% 0%, 100% 100%, 0% 100%,
         0% ${rect.top}px, /* Move to the top of the target */
          ${rect.left}px ${rect.top}px,
          ${rect.left}px ${rect.bottom}px,
          ${rect.right}px ${rect.bottom}px,
          ${rect.right}px ${rect.top}px,
          0% ${rect.top}px /* Return to the outer edge */
        )`,
      }}
    >
      {/* Highlight Box */}
      <div
        style={{
          top: rect.top,
          left: rect.left,
          width: rect.width,
          height: rect.height,
          position: "absolute",
          border: placement !== "center" ? "2px solid rgba(0, 123, 255, 0.5)" : "none",
          boxSizing: "border-box",
          zIndex: 998,
          animation: placement !== "center" ? "pulse 1.3s infinite" : "none",
        }}
      />

      <div
        ref={ref}
        className={`tour-tooltip-wrapper ui-tooltip-title-${placement} tour-tooltip-${placement} ${resolveDirClass(
          language
        )}`}
        style={{ position: "absolute", transition: "all ease 0.5s", zIndex: 1000, ...tooltipPosition }}
      >
        <Card style={{ overflow: "visible" }}>
          <CloseBtn position={{ left: 5, top: 5 }} onClick={endTour} aria-label="Finish Tour" />
          <div className="tour-tooltip-current-step">{currentStep + 1}</div>
          <div className="space-height-sm" />
          <div className="tour-content-wrapper">{content}</div>

          {/* <button onClick={() => targetElement.click()}>click on target</button> */}

          {!steps[currentStep].waitForInteraction && (
            <TourBottom
              nextStep={nextStep}
              prevStep={prevStep}
              setCurrentStep={setCurrentStep}
              steps={steps}
              currentStep={currentStep}
            />
          )}
        </Card>
      </div>
    </div>
  );
  // if (step.waitForInteraction) return tourElement;
  return createPortal(tourElement, document.body);
};

export default Tour;

const TourBottom = ({
  nextStep,
  steps,
  prevStep,
  setCurrentStep,
  currentStep,
}: {
  steps: TourStep[];
  nextStep: () => void;
  prevStep: () => void;
  setCurrentStep: Dispatch<SetStateAction<number>>;
  currentStep: number;
}) => {
  const visibleRange = useMemo(() => {
    const maxDots = 10; // Maximum visible dots
    const totalSteps = steps.length;
    const halfRange = Math.floor(maxDots / 2);

    let start = Math.max(0, currentStep - halfRange);
    let end = start + maxDots;

    // Adjust range if close to the end
    if (end > totalSteps) {
      end = totalSteps;
      start = Math.max(0, end - maxDots);
    }

    return [start, end];
  }, [currentStep, steps.length]);

  const handleNext = () => {
    if (steps[currentStep].targetClickOnNext) {
      const clickableElement = document.querySelector(
        steps[currentStep].targetClickOnNext as string
      ) as HTMLElement | null;
      clickableElement && clickableElement.click();
    }
    // 28 width +3 gap

    nextStep();
  };

  return (
    <Flex justify="space-around" align="center" style={{ marginTop: 10 }}>
      <span
        title={currentStep === steps.length - 1 ? "סיום" : "הבא"}
        className="tour-arrow"
        onClick={handleNext}
        style={{ cursor: "pointer" }}
      >
        {currentStep === steps.length - 1 ? <>&times;</> : "⮞"}
      </span>
      <Flex
        gap={3}
        align="center"
        style={{
          direction: "ltr",
        }}
      >
        {steps.slice(visibleRange[0], visibleRange[1]).map((s, i) => {
          const realIndex = visibleRange[0] + i;
          return (
            <button
              disabled={s.dotDisabled}
              className={`dot ${currentStep === realIndex ? "dot-active" : ""}`}
              style={{ "--label-text": `"${realIndex + 1}"` } as React.CSSProperties}
              key={s.target}
              onClick={() => setCurrentStep(realIndex)}
            />
          );
        })}
      </Flex>
      <span
        title="הקודם"
        className="tour-arrow"
        onClick={prevStep}
        style={{ cursor: currentStep === 0 ? "no-drop" : "pointer" }}
      >
        ⮜
      </span>
    </Flex>
  );
};
