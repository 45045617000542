import { CSSProperties } from "react";
import "./CloseBtn.css";

interface ICloseBtnProps extends React.HTMLAttributes<HTMLButtonElement> {
  color?: string;
  cssClass?: string;
  size?: number | string;
  strong?: boolean;
  type?: "circle-hover" | "circle-start" | "rect-start";
  style?: CSSProperties;
  /**
   * Set the position absolute (must be in relative container)
   * */
  position?: { left?: string | number; right?: string | number; top?: string | number; bottom?: string | number };
}

const CloseBtn = ({ color, cssClass, type, position, size = 24, style, strong, ...rest }: ICloseBtnProps) => {
  const innerStyle: CSSProperties = {
    color,
    fontSize: size,
    width: size,
    height: size,
    lineHeight: typeof size === "string" ? size : `${size}px`,
    ...position,
    ...style,
  };

  if (position) {
    innerStyle.position = "absolute";
  }

  const className = `ui-close-btn ${strong ? "ui-close-btn-bold" : ""} ${type || ""} ${cssClass || ""}`;

  return (
    <button {...rest} style={innerStyle} className={className.trim()}>
      &times;
    </button>
  );
};
export default CloseBtn;
