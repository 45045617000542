import React, { useState, FC } from "react";
import "./Mobile.css";
import { IconCustom } from "../../UI/Icons/types";
import { CartIcon } from "../../UI/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import useCustomContext from "../../../hooks/useCustomContext/useCustomContext";
import Authentication from "../../Authentication/Authentication";
import { useCartStore } from "../../../Zustand";

interface MobileMenuItem {
  value: number;
  Icon: React.FC<IconCustom>;
  left: string;
}

const HomeIcon: FC<IconCustom> = ({ size = "20px", color = "var(--white)", ...rest }) => {
  return (
    <svg fill={color} {...rest} viewBox="0 0 576 512" width={size} height={size}>
      <path d="M543.8 287.6c17 0 32-14 32-32.1c1-9-3-17-11-24L512 185V64c0-17.7-14.3-32-32-32H448c-17.7 0-32 14.3-32 32v36.7L309.5 7c-6-5-14-7-21-7s-15 1-22 8L10 231.5c-7 7-10 15-10 24c0 18 14 32.1 32 32.1h32v69.7c-.1 .9-.1 1.8-.1 2.8V472c0 22.1 17.9 40 40 40h16c1.2 0 2.4-.1 3.6-.2c1.5 .1 3 .2 4.5 .2H160h24c22.1 0 40-17.9 40-40V448 384c0-17.7 14.3-32 32-32h64c17.7 0 32 14.3 32 32v64 24c0 22.1 17.9 40 40 40h24 32.5c1.4 0 2.8 0 4.2-.1c1.1 .1 2.2 .1 3.3 .1h16c22.1 0 40-17.9 40-40V455.8c.3-2.6 .5-5.3 .5-8.1l-.7-160.2h32z" />
    </svg>
  );
};

const ListIcon: FC<IconCustom> = ({ size = "20px", color = "var(--white)", ...rest }) => {
  return (
    <svg fill={color} {...rest} width={size} height={size} viewBox="0 0 576 512">
      <path d="M152.1 38.2c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 113C-2.3 103.6-2.3 88.4 7 79s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zm0 160c9.9 8.9 10.7 24 1.8 33.9l-72 80c-4.4 4.9-10.6 7.8-17.2 7.9s-12.9-2.4-17.6-7L7 273c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l22.1 22.1 55.1-61.2c8.9-9.9 24-10.7 33.9-1.8zM224 96c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H256c-17.7 0-32-14.3-32-32zM160 416c0-17.7 14.3-32 32-32H480c17.7 0 32 14.3 32 32s-14.3 32-32 32H192c-17.7 0-32-14.3-32-32zM48 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96z" />
    </svg>
  );
};

const SettingsIcon: FC<IconCustom> = ({ size = "20px", color = "var(--white)", ...rest }) => {
  return (
    <svg fill={color} {...rest} width={size} height={size} viewBox="0 0 576 512">
      <path d="M0 416c0 17.7 14.3 32 32 32l54.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 448c17.7 0 32-14.3 32-32s-14.3-32-32-32l-246.7 0c-12.3-28.3-40.5-48-73.3-48s-61 19.7-73.3 48L32 384c-17.7 0-32 14.3-32 32zm128 0a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zM320 256a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm32-80c-32.8 0-61 19.7-73.3 48L32 224c-17.7 0-32 14.3-32 32s14.3 32 32 32l246.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48l54.7 0c17.7 0 32-14.3 32-32s-14.3-32-32-32l-54.7 0c-12.3-28.3-40.5-48-73.3-48zM192 128a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm73.3-64C253 35.7 224.8 16 192 16s-61 19.7-73.3 48L32 64C14.3 64 0 78.3 0 96s14.3 32 32 32l86.7 0c12.3 28.3 40.5 48 73.3 48s61-19.7 73.3-48L480 128c17.7 0 32-14.3 32-32s-14.3-32-32-32L265.3 64z" />
    </svg>
  );
};

const MenuIcon: FC<IconCustom> = ({ size = "20px", color = "var(--white)", ...rest }) => {
  return (
    <svg fill={color} {...rest} width={size} height={size} viewBox="0 0 556 512">
      <path d="M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z" />
    </svg>
  );
};

const items: MobileMenuItem[] = [
  { value: 0, Icon: HomeIcon, left: "10%" },
  { value: 1, Icon: CartIcon, left: "30%" },
  { value: 2, Icon: MenuIcon, left: "50%" },
  { value: 3, Icon: ListIcon, left: "70%" },
  { value: 4, Icon: SettingsIcon, left: "90%" },
];

interface IProps {}

const MobileNav: FC<IProps> = () => {
  const {
    setIsShoppingListOpen,
    user: { isUser },
  } = useCustomContext();
  const { setIsCartOpen } = useCartStore();
  const [selectedItem, setSelectedItem] = useState<MobileMenuItem>();
  const [isAuthOpen, setIsAuthOpen] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  const handleCartFunction = () => {
    if (location.pathname !== "/" && !location.pathname.includes("/products/")) navigate("/");
    setIsCartOpen((p) => !p);
  };

  const handleMenuDeselect = () => {
    if (location.pathname !== "/categories/mobile") {
      navigate("/categories/mobile");
      return;
    }
    navigate(-1);
    setSelectedItem(undefined);
  };

  const handleItemClick = (item: MobileMenuItem) => {
    setSelectedItem(item);
    handleFunctions(item.value);
  };

  const handleFunctions = (value: number) => {
    switch (value) {
      case 0:
        navigate("/");
        break;
      case 1:
        handleCartFunction();
        break;
      case 2:
        navigate("/categories/mobile");
        break;
      case 3:
        setIsShoppingListOpen((p) => !p);
        break;
      case 4:
        if (isUser) navigate("/profile/login");
        else setIsAuthOpen((p) => !p);
        break;

      default:
        break;
    }
  };

  const handleCircleClick = () => {
    switch (selectedItem?.value) {
      case 1:
        handleCartFunction();
        break;
      case 2:
        handleMenuDeselect();
        break;
      case 3:
        setIsShoppingListOpen((p) => !p);
        break;
      case 4:
        if (!isUser) setIsAuthOpen((p) => !p);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <footer className="responsive_menu desktop-display-none">
        <ul>
          {items.map((item) => (
            <li
              key={item.value}
              className={selectedItem?.value === item.value ? "remove_icon" : ""}
              onClick={() => handleItemClick(item)}
              value={item.value}
            >
              <item.Icon />
            </li>
          ))}

          {selectedItem && (
            <div id="menu_circle" style={{ left: selectedItem.left }} onClick={handleCircleClick}>
              <li>{<selectedItem.Icon color="white" size="25px" />}</li>
            </div>
          )}
        </ul>
      </footer>
      {isAuthOpen && <Authentication isOpen={isAuthOpen} setIsOpen={setIsAuthOpen} type={"login"} />}
    </>
  );
};

export default MobileNav;
