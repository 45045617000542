import { FC, ReactNode, useMemo, useState } from "react";
import "./Promotion.css";
import { Language, ProductDataType, PromotionTable, StoreEn } from "../../../types/types";
import { dictStores } from "../../../dictionary/dictStores";
import { PromoIcon } from "../../UI/Icons";
import { addSpacingBetweenNumbersAndLetters, directionByLanguage } from "../../../utils/utils";

type Props = {
  productData: ProductDataType;
  allStores: StoreEn[];
  language: Language;
  type?: "main" | "search";
  title?: string | ReactNode;
  endDate?: boolean;
  promoPrice?: boolean;
  alwaysShowData?: boolean;
  promoIconColor?: string;
};

//#region

//* Get promotions from all stores in array of promotion data objects
export const getPromotionData = (allStores: StoreEn[], productData: ProductDataType) => {
  const mixPromotions: PromotionTable[] = [];
  allStores.forEach((store) => {
    if (productData[store]?.promotions?.length) {
      const storePromotions = productData[store]?.promotions;
      storePromotions.forEach((promo) => {
        mixPromotions.push({ ...promo, store: store });
      });
    }
  });
  return mixPromotions;
};
//#endregion

const Promotion: FC<Props> = ({
  allStores,
  productData,
  language,
  type = "main",
  title = "מבצעים",
  promoIconColor,
  endDate = true,
  promoPrice = true,
  alwaysShowData = false,
}) => {
  const [isHovered, setIsHovered] = useState(alwaysShowData);

  const promoData = useMemo(() => {
    return getPromotionData(allStores, productData);
  }, [productData, allStores]);

  const generateDescription = useMemo(() => {
    if (!promoData?.length) return null;

    return promoData.map((p, i) => {
      return (
        <div className="promotion-context" key={p.item_code + p.promotion_id + i}>
          {allStores.length >= 1 && p.store && (
            <h4 className={`promotion-title ${directionByLanguage(language)}`}>
              <PromoIcon size={"32px"} color={promoIconColor} />
              {dictStores[p.store][language]}
            </h4>
          )}
          <div>{addSpacingBetweenNumbersAndLetters(p?.promotion_description)}</div>
          {promoPrice && p.store && (
            <div>
              <span>מחיר רגיל: </span>
              <span>{productData[p.store].item_price}</span>
              <span> שח </span>
              <span>ל- </span>
              <span>{productData[p.store].qty_in_package}</span>
              <span>&nbsp; </span>
              <span>{productData[p.store].unit_qty}</span>
            </div>
          )}
          {endDate && (
            <div>
              <span>בתוקף עד:</span>
              <span>{new Date(p.promotion_end_date).toLocaleDateString()}</span>
            </div>
          )}
        </div>
      );
    });
  }, [promoData, language, allStores.length, endDate, productData, promoPrice, promoIconColor]);

  if (!promoData?.length) return null;

  return (
    <div
      id={`promotion-card-${type}`}
      aria-expanded={isHovered}
      aria-label={typeof title === "string" ? title : "Promotion"}
      className={isHovered ? "scrollable" : ""}
      onMouseEnter={() => !alwaysShowData && setIsHovered(true)}
      onMouseLeave={() => !alwaysShowData && setIsHovered(false)}
      onKeyDown={(e) => {
        if (e.key === "Enter" || e.key === " ") {
          e.preventDefault(); // Prevent default action for space key
          setIsHovered(!isHovered); // Toggle visibility
        }
      }}
    >
      {isHovered ? generateDescription : title}
    </div>
  );
};

export default Promotion;
