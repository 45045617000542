import { create } from "zustand";
import { StoreEn } from "../../types/types";
import { screenSizes } from "../../components/UI/index.hooks";
import { PageComparisonView } from "../../pages/CartComparisonPage/Helpers/pageOptions/options/changeView";

type PageComparisonSettings = {
  storesToCompare: StoreEn[];
  toggleCheckedStore: (store: StoreEn) => void;
  setStoresToCompare: (initialStores: StoreEn[]) => void;
  searchedItemName: string;
  setSearchedItemName: (value: string) => void;
  searchedCategory: string;
  setSearchedCategory: (value: string) => void;
  selectedView: PageComparisonView;
  setSelectedView: (value: PageComparisonView) => void;
  // itemsKeys: string[];
  // setItemsKeys: (keys: string[]) => void;
};

const usePageComparisonSettings = create<PageComparisonSettings>((set, get) => ({
  selectedView: window.innerWidth < screenSizes.sm ? "mobile" : "desktop",
  setSelectedView: (value) => set({ selectedView: value }),
  searchedItemName: "",
  setSearchedItemName: (value) => set({ searchedItemName: value }),
  searchedCategory: "",
  setSearchedCategory: (value) => set({ searchedCategory: value }),
  storesToCompare: [],
  // Action to set the initial value of storesToCompare
  setStoresToCompare: (initialStores) => set({ storesToCompare: initialStores }),
  toggleCheckedStore: (store) => {
    set((state) => {
      if (state.storesToCompare.includes(store)) {
        return { storesToCompare: state.storesToCompare.filter((s) => s !== store) };
      }

      return { storesToCompare: [...state.storesToCompare, store] };
    });
  },

  // itemsKeys: [],
  // setItemsKeys: (keys) => set({ itemsKeys: keys }),
}));

export default usePageComparisonSettings;
