import { ReactNode } from "react";
import { Language } from "../../types/types";

type TourPlacement = "top" | "bottom" | "left" | "right" | "center";

// get tour state from storage
// if

export interface TourStep {
  target: string; // CSS selector
  content: string | ReactNode; // Tooltip content
  placement?: TourPlacement; // Tooltip position
  waitForInteraction?: boolean; // Whether to wait for user action,
  skipOnNotFoundTarget?: boolean; // Will not close the tour if target not found
  awaiting?: boolean;
  targetClickBtn?: boolean; //todo: in tooltip render btn that make target click
  targetClickOnNext?: string;
  dotDisabled?: boolean;
  highlightTarget?: boolean;
}

export type TourDict = {
  [key: string]: {
    content: { [key in Language]: TourStep["content"] };
  } & Omit<TourStep, "content">;
};

export const generateTourStepsFromDict = (language: Language, tourDict: TourDict) => {
  return Object.values(tourDict).map((step) => ({ ...step, content: step.content[language] }));
};

export type TourLocalStorageType = { version: number; seen: boolean };
