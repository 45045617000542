import { TourDict } from "../../../../../../../components/Tour/TourHelpers";

export const pageOptionsTour: TourDict = {
  pageOptionsWrapper: {
    target: "[data-tour=page-options]",
    placement: "bottom",
    highlightTarget: false,
    content: {
      hebrew: "סרגל זה מראה אפשרויות נוספות בכדי להקל עליכם את הפעולות בדף זה",
      english: "",
      arabic: "",
      russian: "",
    },
  },
  selectStoreOptionClick: {
    target: "[data-tour=btn-select_stores]",
    placement: "top",
    waitForInteraction: true,
    content: {
      hebrew: "לחצו על כפתור זה לצורך בחירת חנויות להצגה",
      english: "",
      arabic: "",
      russian: "",
    },
  },
  selectStoreOption: {
    target: "[data-tour=select_stores]",
    placement: "top",
    content: {
      hebrew: "התאימו את מספר החנויות לתצוגה לצורך התצוגה הנוחה ביותר עבורכם",
      english: "",
      arabic: "",
      russian: "",
    },
  },

  searchOptionClick: {
    target: "[data-tour=pc-option-btn_search]",
    // targetClickOnNext: "[data-tour=pc-option-btn_search]",
    waitForInteraction: true,
    placement: "top",
    content: {
      hebrew: "לחצן חיפוש וסינון פריטים",
      english: "",
      arabic: "",
      russian: "",
    },
  },
  searchInCartByName: {
    target: ".data-tour-search-in-cart",
    placement: "top",
    content: {
      hebrew: "חיפוש לפי שם פריט יקל עליכם למצוא פריט בתוך העגלה",
      english: "",
      arabic: "",
      russian: "",
    },
  },
  filterByCategory: {
    target: "[data-tour=filter-categories]",
    placement: "top",
    skipOnNotFoundTarget: true,
    content: {
      hebrew:
        "סינון פריטים לפי קטגוריה נבחרת (אם תסננו קטגוריה אז החיפוש לפי שם יחפש תחת אותה קטגוריה, החזירו לכל הקטגוריות אם ברצונכם לחפש בכל העגלה )",
      english: "",
      arabic: "",
      russian: "",
    },
  },
  dragAndDropCartsClick: {
    target: "[data-tour=btn-dd_carts_stores]",
    targetClickOnNext: "[data-tour=btn-dd_carts_stores]",
    placement: "top",
    content: {
      hebrew: "גרירת ומיון סדר העגלות",
      english: "",
      arabic: "",
      russian: "",
    },
  },
  dragAndDropCart: {
    target: "[data-tour=dd_carts_stores]",
    placement: "top",
    content: {
      hebrew:
        "תוכלו לסדר את מיקומי העגלות לבחירתכם בעזרת גרירה והנחה של שמות הרשתות. רק זכרו שהחנות הראשית תמיד תופיע מימין ואותה לא ניתן להזיז",
      english: "",
      arabic: "",
      russian: "",
    },
  },
  changeView: {
    target: "[data-tour=pc-option-btn_change_view]",
    targetClickOnNext: "[data-tour=pc-option-btn_change_view]",
    placement: "top",
    content: {
      hebrew: "תוכלו לשנות תצוגת השוואה לנוחיותכם. אנו נמשיך לנסות להנגיש לכם דף זה בצורה הטובה ביותר",
      english: "",
      arabic: "",
      russian: "",
    },
  },
  tourBtn: {
    target: "[data-tour=btn-tour]",
    placement: "right",
    highlightTarget: false,
    content: {
      hebrew: "תכף מסיימים! אם תרצו לצפות שוב במדריך זה לחצו על כפתור זה בכל עת שתרצו...",
      english: "",
      arabic: "",
      russian: "",
    },
  },
  end: {
    target: "[data-tour=all-carts]",
    placement: "center",
    content: {
      hebrew: "תודה רבה שהייתם קשובים! בהצלחה בהשוואה והמשך קנייה מהנה ",
      english: "",
      arabic: "",
      russian: "",
    },
  },
};
